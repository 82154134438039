import React from 'react';
import { Box, CircularProgress, Grid, List, ListItemText, Typography } from '@material-ui/core';
import { useApi, useContext } from '../context';
import { Link, Redirect } from 'react-router-dom';
import { DepositWizard, DepositWizardStep } from './wizard';
import { Trans, useTranslation } from 'react-i18next';
import moment from 'moment';
import { QRCode } from 'react-qr-svg';
import { Chain } from '../services/types/chain.type';
import utils from '../services/utils.service';


export const Wallet = () => {
  const api = useApi();
  const context = useContext();

  const { t, i18n } = useTranslation('wallet');
  moment.locale(i18n.language);

  const [loading, setLoading] = React.useState(true);
  const [chain, setChain] = React.useState<Chain | null>(null);

  React.useEffect(() => {
    if (context.data.topup) {
      utils.runAsync(async () => {
        setLoading(true);
        const chains = await api.getChains(context.data.topup!.data.crypto_currency_code);
        const foundChain = chains.find(c => c.code === context.data.topup?.data.crypto_chain_code);
        if (foundChain) {
          setChain(foundChain);
        }
      }, (e) => {
        setLoading(false);
      })
    }
  }, []);

  if (!context.data.topup) {
    return (
      <Redirect to='/' />
    );
  }

  if (!loading && !chain) {
    return (
      <Redirect to='/' />
    );
  }

  let expirationMoment
  if (chain) {
    expirationMoment = moment(context.data.topup.data.created).add(chain!.notification_timeout, 'seconds');
  }

  return (
    <>
      <DepositWizard
        canGoNext={false}
        loading={false}
        back={() => { }}
        next={() => { }}
        step={DepositWizardStep.WALLET}
        showButtons={false}>
        <Grid container spacing={2}>
          <Grid item xs={12}>
            <Typography>
              {t('INTRO')}
            </Typography>
          </Grid>
          {loading &&
            <Grid item xs={12}>
              <Grid container justify='center'>
                <CircularProgress />
              </Grid>
            </Grid>
          }
          {!loading &&
            <>
              <Grid item container xs={12} spacing={1}>
                <Grid item container xs={12} justify='center'>
                  <Box style={{ maxWidth: '100%', width: 150, marginTop: 25 }}>
                    <QRCode value={context.data.topup.wallet.address} />
                  </Box>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='h4' align='center'>
                    {`${context.data.topup.data.crypto_amount} ${context.data.topup.data.crypto_currency_code.toUpperCase()}`}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <Typography variant='subtitle2' align='center'>
                    {context.data.topup.wallet.address}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  {context.data.topup.wallet.tag &&
                    <Typography variant='subtitle2' align='center'>
                      {`${t('TAG')}${context.data.topup.wallet.tag}`}
                    </Typography>
                  }
                </Grid>
              </Grid>
              <Grid item container xs={12} spacing={1}>
                <Grid item xs={12}>
                  <Typography variant='h4' gutterBottom>
                    {t('INSTRUCTIONS_TITLE')}
                  </Typography>
                </Grid>
                <Grid item xs={12}>
                  <List>
                    <ListItemText>{t('AMOUNT_INSTRUCTIONS', { amount: `${context.data.topup.data.crypto_amount} ${context.data.topup.data.crypto_chain_code.toUpperCase()}` })</ListItemText>
                    <ListItemText>{t('TIMEOUT_INSTRUCTIONS', { expiration: expirationMoment?.format('lll') })}</ListItemText>
                    <ListItemText>{t('CONFIRMATION_INSTRUCTIONS',{confirmations: chain?.confirmations})}</ListItemText>
                    <ListItemText>{t('FEE_INSTRUCTIONS', { fee: chain!.fee })}</ListItemText>
                    <ListItemText>
                      <Trans i18nKey={'DEPOSIT_HISTORY_INSTRUCTIONS'} t={t}>
                        <Typography>
                          CHECK TRANSACTIONS
                          <Link target='_blank' rel='noopener' to='/account'>MY ACCOUNT</Link>
                        </Typography>
                      </Trans>
                    </ListItemText>
                  </List>
                </Grid>
              </Grid>
            </>
          }
        </Grid>
      </DepositWizard>
    </>
  );
}
