import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableContainer from '@material-ui/core/TableContainer';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Paper from '@material-ui/core/Paper';
import InfoIcon from '@material-ui/icons/Info';
import cryptoImage from '../assets/img/crypto.svg';
import {
  Box,
  Button,
  ButtonGroup,
  Chip,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Grid,
  List,
  ListItem,
  ListItemText,
  TablePagination,
  Theme,
  Typography
} from '@material-ui/core';
import WalletIcon from '@material-ui/icons/Info';
import utils from '../services/utils.service';
import { useApi, useContext } from '../context';
import { useTranslation } from 'react-i18next';
import { Link, useHistory } from 'react-router-dom';
import moment from 'moment';
import { Topup, TopupStatus } from '../services/types/topup.type';
import { LoadingButton } from '../components/loadingButton';

const useStyles = makeStyles((theme: Theme) => ({
  img: {
    maxWidth: '90%',
    width: 300,
  },
  locationButtonListItem: {
    padding: theme.spacing(0.25, 1),
    marginTop: theme.spacing(2),
    justifyContent: 'center'
  },
  listItem: {
    padding: theme.spacing(0.25, 1),
  },
  listItemText: {
    margin: theme.spacing(0)
  }
}));

export const TransactionDetails = ({ transaction, onClose }: { transaction: Topup | null, onClose: () => void }) => {
  const { t } = useTranslation('account');
  const api = useApi();
  const context = useContext();
  const classes = useStyles();
  const history = useHistory();

  const [loading, setLoading] = React.useState(false);


  if (!transaction) return (<></>);

  const showWallet = () => {
    utils.runAsync(async () => {
      setLoading(true);
      const wallet = await api.getWalletForTopup(transaction.id);
      context.setData({
        topup: {
          data: transaction,
          wallet
        }
      });
      history.push('/deposit/wallet');
    }, () => {
      setLoading(false);
    });

  }
  return (
    <>
      <Dialog
        open={transaction !== null}
        fullWidth
        onClose={onClose}
        scroll={'paper'}
      >
        <DialogTitle>{t('TOPUP_DETAILS_TITLE')}</DialogTitle>
        <DialogContent dividers>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography variant="h5" gutterBottom>
                {t('DEPOSIT')}
              </Typography>
              <Grid item xs={12} container>
                <Grid item xs={12} sm={6}>
                  <List disablePadding>
                    <ListItem className={classes.listItem}>
                      <ListItemText primary={t('DATE')} secondary={moment(transaction.created).format('lll')} className={classes.listItemText} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemText primary={t('REQUESTED_AMOUNT')} secondary={`${transaction.crypto_amount} ${transaction.crypto_currency_code.toUpperCase()}`} className={classes.listItemText} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemText primary={t('DEPOSITED_AMOUNT')} secondary={transaction.deposit_crypto_amount? `${transaction.deposit_crypto_amount} ${transaction.crypto_currency_code.toUpperCase()}` : '-'} className={classes.listItemText} />
                    </ListItem>
                    <ListItem className={classes.listItem}>
                      <ListItemText
                        primary={t('STATUS')}
                        secondary={t(transaction.status)} className={classes.listItemText}
                      />
                    </ListItem>
                    {transaction.updated &&
                      <ListItem className={classes.listItem}>
                        <ListItemText
                          primary={t('UPDATED')}
                          secondary={moment(transaction.updated).format('lll')} className={classes.listItemText}
                        />
                      </ListItem>
                    }
                  </List>
                </Grid>
              </Grid>
            </Grid>
            {transaction.transfer_amount && transaction.fee !== null &&
              <Grid item xs={12}>
                <Typography variant="h5" gutterBottom>
                  {t('TRANSFER')}
                </Typography>
                <List disablePadding>
                  <ListItem className={classes.listItem}>
                    <ListItemText primary={t('TRANSFER_AMOUNT')} />
                    <Typography component='span'>{`${utils.currencyFormat(transaction.transfer_amount)} €UR`}</Typography>
                  </ListItem>
                  <ListItem className={classes.listItem}>
                    <ListItemText primary={t('DEDUCTED_FEE')} />
                    <Typography component='span'>{`${utils.currencyFormat(transaction.fee)} €UR`}</Typography>
                  </ListItem>
                </List>
              </Grid>

            }
          </Grid>
        </DialogContent>
        <DialogActions>
          {transaction.status === TopupStatus.PENDING &&
            <LoadingButton loading={loading} disabled={loading} onClick={() => {
              showWallet();
            }} variant='outlined' startIcon={<WalletIcon />}>
              {t('DEPOSIT_DETAILS')}
            </LoadingButton>
          }
          <Button onClick={onClose} color='primary'>
            {t('OK')}
          </Button>
        </DialogActions>
      </Dialog>
    </>
  );
}

export const Topups = () => {
  const classes = useStyles();
  const api = useApi();

  const { t, i18n } = useTranslation('account');
  moment.locale(i18n.language);

  const [loading, setLoading] = React.useState(true);
  const [transactions, setTransactions] = React.useState<Topup[]>([]);
  const [detailTransaction, setDetailTransaction] = React.useState<Topup | null>(null);
  const [count, setCount] = React.useState(0);
  const [page, setPage] = React.useState(0);
  const limit = 10;

  React.useEffect(() => {
    utils.runAsync(async () => {
      setLoading(true);
      const { topups, count } = await api.getTopups({ limit, offset: page * limit, order: 'desc' });
      setCount(count);
      setTransactions(topups);
    }, () => {
      setLoading(false);
    });
  }, [page]);

  const getColorForStatus = (status: TopupStatus) => {
    if (status === TopupStatus.PENDING) {
      return 'secondary';
    }
    if (status === TopupStatus.TIME_OUT) {
      return 'default';
    }
    return 'primary';
  }

  return (
    <>
      <TransactionDetails transaction={detailTransaction} onClose={() => { setDetailTransaction(null) }} />
      <Paper>
        <Box paddingTop={2} paddingLeft={1} paddingBottom={1}>
          <Typography variant="h5">
            {t('TOPUPS')}
          </Typography>
        </Box>
        {loading &&
          <Grid item container justify='center'>
            <Box padding={4}>
              <CircularProgress />
            </Box>
          </Grid>
        }
        {!loading && transactions.length > 0 &&
          <>
            <TableContainer>
              <Table>
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell align='center'>{t('DATE')}</TableCell>
                    <TableCell align='center'>{t('AMOUNT')}</TableCell>
                    <TableCell align='center'>{t('STATUS')}</TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {transactions.map(tr => (
                    <TableRow key={tr.id}>
                      <TableCell>
                        <ButtonGroup variant='text' color='secondary'>
                          <Button onClick={() => { setDetailTransaction(tr) }}>
                            <InfoIcon fontSize="small" />
                          </Button>
                        </ButtonGroup>
                      </TableCell>
                      <TableCell align='center'>{moment(tr.created).format('lll')}</TableCell>
                      <TableCell align='center'>{`${tr.deposit_crypto_amount ? tr.deposit_crypto_amount: tr.crypto_amount} ${tr.crypto_currency_code.toUpperCase()}`}</TableCell>
                      <TableCell align='center'><Chip size='small' color={getColorForStatus(tr.status)} label={t(tr.status)} /></TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>
            <TablePagination
              component='div'
              rowsPerPageOptions={[limit]}
              count={count}
              rowsPerPage={limit}
              page={page}
              onChangePage={(event, page) => { setPage(page) }}
            />
          </>
        }
        {!loading && transactions.length === 0 &&
          <Box padding={4}>
            <Grid container spacing={1} justify='center'>
              <Grid item>
                <img src={cryptoImage} className={classes.img} />
              </Grid>
              <Grid item xs={12}>
                <Typography gutterBottom variant='subtitle2' align='center'>
                  {t('NO_TOPUPS')}
                </Typography>
              </Grid>
              <Grid item>
                <Button component={Link} to='/deposit' variant='outlined' size='large' color='secondary'>{t('NEW_DEPOSIT')}</Button>
              </Grid>
            </Grid>
          </Box>
        }
      </Paper>
    </>
  );
}