import abt from './assets/cryptoicons/abt.svg'
import act from './assets/cryptoicons/act.svg'
import actn from './assets/cryptoicons/actn.svg'
import ada from './assets/cryptoicons/ada.svg'
import add from './assets/cryptoicons/add.svg'
import adx from './assets/cryptoicons/adx.svg'
import ae from './assets/cryptoicons/ae.svg'
import aeon from './assets/cryptoicons/aeon.svg'
import aeur from './assets/cryptoicons/aeur.svg'
import agi from './assets/cryptoicons/agi.svg'
import agrs from './assets/cryptoicons/agrs.svg'
import aion from './assets/cryptoicons/aion.svg'
import amb from './assets/cryptoicons/amb.svg'
import amp from './assets/cryptoicons/amp.svg'
import ampl from './assets/cryptoicons/ampl.svg'
import ant from './assets/cryptoicons/ant.svg'
import appc from './assets/cryptoicons/appc.svg'
import ardr from './assets/cryptoicons/ardr.svg'
import arg from './assets/cryptoicons/arg.svg'
import ark from './assets/cryptoicons/ark.svg'
import arn from './assets/cryptoicons/arn.svg'
import ary from './assets/cryptoicons/ary.svg'
import ast from './assets/cryptoicons/ast.svg'
import atm from './assets/cryptoicons/atm.svg'
import atom from './assets/cryptoicons/atom.svg'
import audr from './assets/cryptoicons/audr.svg'
import auto from './assets/cryptoicons/auto.svg'
import aywa from './assets/cryptoicons/aywa.svg'
import bab from './assets/cryptoicons/bab.svg'
import bat from './assets/cryptoicons/bat.svg'
import bay from './assets/cryptoicons/bay.svg'
import bcbc from './assets/cryptoicons/bcbc.svg'
import bcc from './assets/cryptoicons/bcc.svg'
import bcd from './assets/cryptoicons/bcd.svg'
import bch from './assets/cryptoicons/bch.svg'
import bcio from './assets/cryptoicons/bcio.svg'
import bcn from './assets/cryptoicons/bcn.svg'
import bco from './assets/cryptoicons/bco.svg'
import bcpt from './assets/cryptoicons/bcpt.svg'
import bdl from './assets/cryptoicons/bdl.svg'
import beam from './assets/cryptoicons/beam.svg'
import bela from './assets/cryptoicons/bela.svg'
import bix from './assets/cryptoicons/bix.svg'
import blcn from './assets/cryptoicons/blcn.svg'
import blk from './assets/cryptoicons/blk.svg'
import block from './assets/cryptoicons/block.svg'
import blz from './assets/cryptoicons/blz.svg'
import bnb from './assets/cryptoicons/bnb.svg'
import bnt from './assets/cryptoicons/bnt.svg'
import bnty from './assets/cryptoicons/bnty.svg'
import booty from './assets/cryptoicons/booty.svg'
import bos from './assets/cryptoicons/bos.svg'
import bpt from './assets/cryptoicons/bpt.svg'
import bq from './assets/cryptoicons/bq.svg'
import brd from './assets/cryptoicons/brd.svg'
import bsd from './assets/cryptoicons/bsd.svg'
import bsv from './assets/cryptoicons/bsv.svg'
import btc from './assets/cryptoicons/btc.svg'
import btcd from './assets/cryptoicons/btcd.svg'
import btch from './assets/cryptoicons/btch.svg'
import btcp from './assets/cryptoicons/btcp.svg'
import btcz from './assets/cryptoicons/btcz.svg'
import btdx from './assets/cryptoicons/btdx.svg'
import btg from './assets/cryptoicons/btg.svg'
import btm from './assets/cryptoicons/btm.svg'
import bts from './assets/cryptoicons/bts.svg'
import btt from './assets/cryptoicons/btt.svg'
import btx from './assets/cryptoicons/btx.svg'
import burst from './assets/cryptoicons/burst.svg'
import bze from './assets/cryptoicons/bze.svg'
import call from './assets/cryptoicons/call.svg'
import cc from './assets/cryptoicons/cc.svg'
import cdn from './assets/cryptoicons/cdn.svg'
import cdt from './assets/cryptoicons/cdt.svg'
import chain from './assets/cryptoicons/chain.svg'
import chat from './assets/cryptoicons/chat.svg'
import chips from './assets/cryptoicons/chips.svg'
import cix from './assets/cryptoicons/cix.svg'
import clam from './assets/cryptoicons/clam.svg'
import cloak from './assets/cryptoicons/cloak.svg'
import cmm from './assets/cryptoicons/cmm.svg'
import cmt from './assets/cryptoicons/cmt.svg'
import cnd from './assets/cryptoicons/cnd.svg'
import cnx from './assets/cryptoicons/cnx.svg'
import cny from './assets/cryptoicons/cny.svg'
import cob from './assets/cryptoicons/cob.svg'
import colx from './assets/cryptoicons/colx.svg'
import coqui from './assets/cryptoicons/coqui.svg'
import cred from './assets/cryptoicons/cred.svg'
import crpt from './assets/cryptoicons/crpt.svg'
import crw from './assets/cryptoicons/crw.svg'
import cs from './assets/cryptoicons/cs.svg'
import ctr from './assets/cryptoicons/ctr.svg'
import ctxc from './assets/cryptoicons/ctxc.svg'
import cvc from './assets/cryptoicons/cvc.svg'
import d from './assets/cryptoicons/d.svg'
import dash from './assets/cryptoicons/dash.svg'
import dat from './assets/cryptoicons/dat.svg'
import data from './assets/cryptoicons/data.svg'
import dbc from './assets/cryptoicons/dbc.svg'
import dcn from './assets/cryptoicons/dcn.svg'
import dcr from './assets/cryptoicons/dcr.svg'
import deez from './assets/cryptoicons/deez.svg'
import dent from './assets/cryptoicons/dent.svg'
import dew from './assets/cryptoicons/dew.svg'
import dgb from './assets/cryptoicons/dgb.svg'
import dgd from './assets/cryptoicons/dgd.svg'
import dlt from './assets/cryptoicons/dlt.svg'
import dnt from './assets/cryptoicons/dnt.svg'
import dock from './assets/cryptoicons/dock.svg'
import doge from './assets/cryptoicons/doge.svg'
import dot from './assets/cryptoicons/dot.svg'
import drgn from './assets/cryptoicons/drgn.svg'
import drop from './assets/cryptoicons/drop.svg'
import dta from './assets/cryptoicons/dta.svg'
import dth from './assets/cryptoicons/dth.svg'
import dtr from './assets/cryptoicons/dtr.svg'
import ebst from './assets/cryptoicons/ebst.svg'
import eca from './assets/cryptoicons/eca.svg'
import edg from './assets/cryptoicons/edg.svg'
import edo from './assets/cryptoicons/edo.svg'
import edoge from './assets/cryptoicons/edoge.svg'
import ela from './assets/cryptoicons/ela.svg'
import elec from './assets/cryptoicons/elec.svg'
import elf from './assets/cryptoicons/elf.svg'
import elix from './assets/cryptoicons/elix.svg'
import ella from './assets/cryptoicons/ella.svg'
import emc from './assets/cryptoicons/emc.svg'
import emc2 from './assets/cryptoicons/emc2.svg'
import eng from './assets/cryptoicons/eng.svg'
import enj from './assets/cryptoicons/enj.svg'
import entrp from './assets/cryptoicons/entrp.svg'
import eon from './assets/cryptoicons/eon.svg'
import eop from './assets/cryptoicons/eop.svg'
import eos from './assets/cryptoicons/eos.svg'
import eqli from './assets/cryptoicons/eqli.svg'
import equa from './assets/cryptoicons/equa.svg'
import etc from './assets/cryptoicons/etc.svg'
import eth from './assets/cryptoicons/eth.svg'
import ethos from './assets/cryptoicons/ethos.svg'
import etn from './assets/cryptoicons/etn.svg'
import etp from './assets/cryptoicons/etp.svg'
import eur from './assets/cryptoicons/eur.svg'
import evx from './assets/cryptoicons/evx.svg'
import exmo from './assets/cryptoicons/exmo.svg'
import exp from './assets/cryptoicons/exp.svg'
import fair from './assets/cryptoicons/fair.svg'
import fct from './assets/cryptoicons/fct.svg'
import fil from './assets/cryptoicons/fil.svg'
import fjc from './assets/cryptoicons/fjc.svg'
import fldc from './assets/cryptoicons/fldc.svg'
import flo from './assets/cryptoicons/flo.svg'
import fsn from './assets/cryptoicons/fsn.svg'
import ftc from './assets/cryptoicons/ftc.svg'
import fuel from './assets/cryptoicons/fuel.svg'
import fun from './assets/cryptoicons/fun.svg'
import game from './assets/cryptoicons/game.svg'
import gas from './assets/cryptoicons/gas.svg'
import gbp from './assets/cryptoicons/gbp.svg'
import gbx from './assets/cryptoicons/gbx.svg'
import gbyte from './assets/cryptoicons/gbyte.svg'
import generic from './assets/cryptoicons/generic.svg'
import gin from './assets/cryptoicons/gin.svg'
import glxt from './assets/cryptoicons/glxt.svg'
import gmr from './assets/cryptoicons/gmr.svg'
import gno from './assets/cryptoicons/gno.svg'
import gnt from './assets/cryptoicons/gnt.svg'
import gold from './assets/cryptoicons/gold.svg'
import grc from './assets/cryptoicons/grc.svg'
import grin from './assets/cryptoicons/grin.svg'
import grs from './assets/cryptoicons/grs.svg'
import gsc from './assets/cryptoicons/gsc.svg'
import gto from './assets/cryptoicons/gto.svg'
import gup from './assets/cryptoicons/gup.svg'
import gusd from './assets/cryptoicons/gusd.svg'
import gvt from './assets/cryptoicons/gvt.svg'
import gxs from './assets/cryptoicons/gxs.svg'
import gzr from './assets/cryptoicons/gzr.svg'
import hight from './assets/cryptoicons/hight.svg'
import hodl from './assets/cryptoicons/hodl.svg'
import hot from './assets/cryptoicons/hot.svg'
import hpb from './assets/cryptoicons/hpb.svg'
import hsr from './assets/cryptoicons/hsr.svg'
import ht from './assets/cryptoicons/ht.svg'
import html from './assets/cryptoicons/html.svg'
import huc from './assets/cryptoicons/huc.svg'
import hush from './assets/cryptoicons/hush.svg'
import icn from './assets/cryptoicons/icn.svg'
import icx from './assets/cryptoicons/icx.svg'
import ignis from './assets/cryptoicons/ignis.svg'
import ilk from './assets/cryptoicons/ilk.svg'
import ink from './assets/cryptoicons/ink.svg'
import ins from './assets/cryptoicons/ins.svg'
import ion from './assets/cryptoicons/ion.svg'
import iop from './assets/cryptoicons/iop.svg'
import iost from './assets/cryptoicons/iost.svg'
import iotx from './assets/cryptoicons/iotx.svg'
import iq from './assets/cryptoicons/iq.svg'
import itc from './assets/cryptoicons/itc.svg'
import jnt from './assets/cryptoicons/jnt.svg'
import jpy from './assets/cryptoicons/jpy.svg'
import kcs from './assets/cryptoicons/kcs.svg'
import kin from './assets/cryptoicons/kin.svg'
import klown from './assets/cryptoicons/klown.svg'
import kmd from './assets/cryptoicons/kmd.svg'
import knc from './assets/cryptoicons/knc.svg'
import krb from './assets/cryptoicons/krb.svg'
import lbc from './assets/cryptoicons/lbc.svg'
import lend from './assets/cryptoicons/lend.svg'
import leo from './assets/cryptoicons/leo.svg'
import link from './assets/cryptoicons/link.svg'
import lkk from './assets/cryptoicons/lkk.svg'
import loom from './assets/cryptoicons/loom.svg'
import lpt from './assets/cryptoicons/lpt.svg'
import lrc from './assets/cryptoicons/lrc.svg'
import lsk from './assets/cryptoicons/lsk.svg'
import ltc from './assets/cryptoicons/ltc.svg'
import lun from './assets/cryptoicons/lun.svg'
import maid from './assets/cryptoicons/maid.svg'
import mana from './assets/cryptoicons/mana.svg'
import matic from './assets/cryptoicons/matic.svg'
import mcap from './assets/cryptoicons/mcap.svg'
import mco from './assets/cryptoicons/mco.svg'
import mda from './assets/cryptoicons/mda.svg'
import mds from './assets/cryptoicons/mds.svg'
import med from './assets/cryptoicons/med.svg'
import meetone from './assets/cryptoicons/meetone.svg'
import mft from './assets/cryptoicons/mft.svg'
import miota from './assets/cryptoicons/miota.svg'
import mith from './assets/cryptoicons/mith.svg'
import mkr from './assets/cryptoicons/mkr.svg'
import mln from './assets/cryptoicons/mln.svg'
import mnx from './assets/cryptoicons/mnx.svg'
import mnz from './assets/cryptoicons/mnz.svg'
import moac from './assets/cryptoicons/moac.svg'
import mod from './assets/cryptoicons/mod.svg'
import mona from './assets/cryptoicons/mona.svg'
import msr from './assets/cryptoicons/msr.svg'
import mth from './assets/cryptoicons/mth.svg'
import mtl from './assets/cryptoicons/mtl.svg'
import music from './assets/cryptoicons/music.svg'
import mzc from './assets/cryptoicons/mzc.svg'
import nano from './assets/cryptoicons/nano.svg'
import nas from './assets/cryptoicons/nas.svg'
import nav from './assets/cryptoicons/nav.svg'
import ncash from './assets/cryptoicons/ncash.svg'
import ndz from './assets/cryptoicons/ndz.svg'
import nebl from './assets/cryptoicons/nebl.svg'
import neo from './assets/cryptoicons/neo.svg'
import neos from './assets/cryptoicons/neos.svg'
import neu from './assets/cryptoicons/neu.svg'
import nexo from './assets/cryptoicons/nexo.svg'
import ngc from './assets/cryptoicons/ngc.svg'
import nio from './assets/cryptoicons/nio.svg'
import nlc2 from './assets/cryptoicons/nlc2.svg'
import nlg from './assets/cryptoicons/nlg.svg'
import nmc from './assets/cryptoicons/nmc.svg'
import npxs from './assets/cryptoicons/npxs.svg'
import nuls from './assets/cryptoicons/nuls.svg'
import nxs from './assets/cryptoicons/nxs.svg'
import nxt from './assets/cryptoicons/nxt.svg'
import oax from './assets/cryptoicons/oax.svg'
import ok from './assets/cryptoicons/ok.svg'
import omg from './assets/cryptoicons/omg.svg'
import omni from './assets/cryptoicons/omni.svg'
import ong from './assets/cryptoicons/ong.svg'
import ont from './assets/cryptoicons/ont.svg'
import oot from './assets/cryptoicons/oot.svg'
import ost from './assets/cryptoicons/ost.svg'
import ox from './assets/cryptoicons/ox.svg'
import part from './assets/cryptoicons/part.svg'
import pasc from './assets/cryptoicons/pasc.svg'
import pasl from './assets/cryptoicons/pasl.svg'
import pax from './assets/cryptoicons/pax.svg'
import pay from './assets/cryptoicons/pay.svg'
import payx from './assets/cryptoicons/payx.svg'
import pink from './assets/cryptoicons/pink.svg'
import pirl from './assets/cryptoicons/pirl.svg'
import pivx from './assets/cryptoicons/pivx.svg'
import plr from './assets/cryptoicons/plr.svg'
import poa from './assets/cryptoicons/poa.svg'
import poe from './assets/cryptoicons/poe.svg'
import polis from './assets/cryptoicons/polis.svg'
import poly from './assets/cryptoicons/poly.svg'
import pot from './assets/cryptoicons/pot.svg'
import powr from './assets/cryptoicons/powr.svg'
import ppc from './assets/cryptoicons/ppc.svg'
import ppp from './assets/cryptoicons/ppp.svg'
import ppt from './assets/cryptoicons/ppt.svg'
import pre from './assets/cryptoicons/pre.svg'
import prl from './assets/cryptoicons/prl.svg'
import pungo from './assets/cryptoicons/pungo.svg'
import pura from './assets/cryptoicons/pura.svg'
import qash from './assets/cryptoicons/qash.svg'
import qiwi from './assets/cryptoicons/qiwi.svg'
import qlc from './assets/cryptoicons/qlc.svg'
import qrl from './assets/cryptoicons/qrl.svg'
import qsp from './assets/cryptoicons/qsp.svg'
import qtum from './assets/cryptoicons/qtum.svg'
import r from './assets/cryptoicons/r.svg'
import rads from './assets/cryptoicons/rads.svg'
import rap from './assets/cryptoicons/rap.svg'
import rcn from './assets/cryptoicons/rcn.svg'
import rdd from './assets/cryptoicons/rdd.svg'
import rdn from './assets/cryptoicons/rdn.svg'
import ren from './assets/cryptoicons/ren.svg'
import rep from './assets/cryptoicons/rep.svg'
import req from './assets/cryptoicons/req.svg'
import rhoc from './assets/cryptoicons/rhoc.svg'
import ric from './assets/cryptoicons/ric.svg'
import rise from './assets/cryptoicons/rise.svg'
import rlc from './assets/cryptoicons/rlc.svg'
import rpx from './assets/cryptoicons/rpx.svg'
import rub from './assets/cryptoicons/rub.svg'
import rvn from './assets/cryptoicons/rvn.svg'
import ryo from './assets/cryptoicons/ryo.svg'
import safe from './assets/cryptoicons/safe.svg'
import sai from './assets/cryptoicons/sai.svg'
import salt from './assets/cryptoicons/salt.svg'
import san from './assets/cryptoicons/san.svg'
import sbd from './assets/cryptoicons/sbd.svg'
import sberbank from './assets/cryptoicons/sberbank.svg'
import sc from './assets/cryptoicons/sc.svg'
import shift from './assets/cryptoicons/shift.svg'
import sib from './assets/cryptoicons/sib.svg'
import sin from './assets/cryptoicons/sin.svg'
import sky from './assets/cryptoicons/sky.svg'
import slr from './assets/cryptoicons/slr.svg'
import sls from './assets/cryptoicons/sls.svg'
import smart from './assets/cryptoicons/smart.svg'
import sngls from './assets/cryptoicons/sngls.svg'
import snm from './assets/cryptoicons/snm.svg'
import snt from './assets/cryptoicons/snt.svg'
import soc from './assets/cryptoicons/soc.svg'
import spank from './assets/cryptoicons/spank.svg'
import sphtx from './assets/cryptoicons/sphtx.svg'
import srn from './assets/cryptoicons/srn.svg'
import stak from './assets/cryptoicons/stak.svg'
import start from './assets/cryptoicons/start.svg'
import steem from './assets/cryptoicons/steem.svg'
import storj from './assets/cryptoicons/storj.svg'
import storm from './assets/cryptoicons/storm.svg'
import stq from './assets/cryptoicons/stq.svg'
import strat from './assets/cryptoicons/strat.svg'
import sub from './assets/cryptoicons/sub.svg'
import sumo from './assets/cryptoicons/sumo.svg'
import sys from './assets/cryptoicons/sys.svg'
import taas from './assets/cryptoicons/taas.svg'
import tau from './assets/cryptoicons/tau.svg'
import tbx from './assets/cryptoicons/tbx.svg'
import tel from './assets/cryptoicons/tel.svg'
import ten from './assets/cryptoicons/ten.svg'
import tern from './assets/cryptoicons/tern.svg'
import tgch from './assets/cryptoicons/tgch.svg'
import theta from './assets/cryptoicons/theta.svg'
import tix from './assets/cryptoicons/tix.svg'
import tkn from './assets/cryptoicons/tkn.svg'
import tks from './assets/cryptoicons/tks.svg'
import tnb from './assets/cryptoicons/tnb.svg'
import tnc from './assets/cryptoicons/tnc.svg'
import tnt from './assets/cryptoicons/tnt.svg'
import tomo from './assets/cryptoicons/tomo.svg'
import tpay from './assets/cryptoicons/tpay.svg'
import trig from './assets/cryptoicons/trig.svg'
import trtl from './assets/cryptoicons/trtl.svg'
import trx from './assets/cryptoicons/trx.svg'
import tusd from './assets/cryptoicons/tusd.svg'
import tzc from './assets/cryptoicons/tzc.svg'
import ubq from './assets/cryptoicons/ubq.svg'
import unity from './assets/cryptoicons/unity.svg'
import usd from './assets/cryptoicons/usd.svg'
import usdc from './assets/cryptoicons/usdc.svg'
import usdt from './assets/cryptoicons/usdt.svg'
import utk from './assets/cryptoicons/utk.svg'
import veri from './assets/cryptoicons/veri.svg'
import vet from './assets/cryptoicons/vet.svg'
import via from './assets/cryptoicons/via.svg'
import vib from './assets/cryptoicons/vib.svg'
import vibe from './assets/cryptoicons/vibe.svg'
import vivo from './assets/cryptoicons/vivo.svg'
import vrc from './assets/cryptoicons/vrc.svg'
import vrsc from './assets/cryptoicons/vrsc.svg'
import vtc from './assets/cryptoicons/vtc.svg'
import vtho from './assets/cryptoicons/vtho.svg'
import wabi from './assets/cryptoicons/wabi.svg'
import wan from './assets/cryptoicons/wan.svg'
import waves from './assets/cryptoicons/waves.svg'
import wax from './assets/cryptoicons/wax.svg'
import wgr from './assets/cryptoicons/wgr.svg'
import wicc from './assets/cryptoicons/wicc.svg'
import wings from './assets/cryptoicons/wings.svg'
import wpr from './assets/cryptoicons/wpr.svg'
import wtc from './assets/cryptoicons/wtc.svg'
import x from './assets/cryptoicons/x.svg'
import xas from './assets/cryptoicons/xas.svg'
import xbc from './assets/cryptoicons/xbc.svg'
import xbp from './assets/cryptoicons/xbp.svg'
import xby from './assets/cryptoicons/xby.svg'
import xcp from './assets/cryptoicons/xcp.svg'
import xdn from './assets/cryptoicons/xdn.svg'
import xem from './assets/cryptoicons/xem.svg'
import xin from './assets/cryptoicons/xin.svg'
import xlm from './assets/cryptoicons/xlm.svg'
import xmcc from './assets/cryptoicons/xmcc.svg'
import xmg from './assets/cryptoicons/xmg.svg'
import xmo from './assets/cryptoicons/xmo.svg'
import xmr from './assets/cryptoicons/xmr.svg'
import xmy from './assets/cryptoicons/xmy.svg'
import xp from './assets/cryptoicons/xp.svg'
import xpa from './assets/cryptoicons/xpa.svg'
import xpm from './assets/cryptoicons/xpm.svg'
import xrp from './assets/cryptoicons/xrp.svg'
import xsg from './assets/cryptoicons/xsg.svg'
import xtz from './assets/cryptoicons/xtz.svg'
import xuc from './assets/cryptoicons/xuc.svg'
import xvc from './assets/cryptoicons/xvc.svg'
import xvg from './assets/cryptoicons/xvg.svg'
import xzc from './assets/cryptoicons/xzc.svg'
import yfi from './assets/cryptoicons/yfi.svg'
import yoyow from './assets/cryptoicons/yoyow.svg'
import zcl from './assets/cryptoicons/zcl.svg'
import zec from './assets/cryptoicons/zec.svg'
import zel from './assets/cryptoicons/zel.svg'
import zen from './assets/cryptoicons/zen.svg'
import zest from './assets/cryptoicons/zest.svg'
import zil from './assets/cryptoicons/zil.svg'
import zilla from './assets/cryptoicons/zilla.svg'
import zrx from './assets/cryptoicons/zrx.svg'

export default {
  abt,
  act,
  actn,
  ada,
  add,
  adx,
  ae,
  aeon,
  aeur,
  agi,
  agrs,
  aion,
  amb,
  amp,
  ampl,
  ant,
  appc,
  ardr,
  arg,
  ark,
  arn,
  ary,
  ast,
  atm,
  atom,
  audr,
  auto,
  aywa,
  bab,
  bat,
  bay,
  bcbc,
  bcc,
  bcd,
  bch,
  bcio,
  bcn,
  bco,
  bcpt,
  bdl,
  beam,
  bela,
  bix,
  blcn,
  blk,
  block,
  blz,
  bnb,
  bnt,
  bnty,
  booty,
  bos,
  bpt,
  bq,
  brd,
  bsd,
  bsv,
  btc,
  btcd,
  btch,
  btcp,
  btcz,
  btdx,
  btg,
  btm,
  bts,
  btt,
  btx,
  burst,
  bze,
  call,
  cc,
  cdn,
  cdt,
  chain,
  chat,
  chips,
  cix,
  clam,
  cloak,
  cmm,
  cmt,
  cnd,
  cnx,
  cny,
  cob,
  colx,
  coqui,
  cred,
  crpt,
  crw,
  cs,
  ctr,
  ctxc,
  cvc,
  d,
  dash,
  dat,
  data,
  dbc,
  dcn,
  dcr,
  deez,
  dent,
  dew,
  dgb,
  dgd,
  dlt,
  dnt,
  dock,
  doge,
  dot,
  drgn,
  drop,
  dta,
  dth,
  dtr,
  ebst,
  eca,
  edg,
  edo,
  edoge,
  ela,
  elec,
  elf,
  elix,
  ella,
  emc,
  emc2,
  eng,
  enj,
  entrp,
  eon,
  eop,
  eos,
  eqli,
  equa,
  etc,
  eth,
  ethos,
  etn,
  etp,
  eur,
  evx,
  exmo,
  exp,
  fair,
  fct,
  fil,
  fjc,
  fldc,
  flo,
  fsn,
  ftc,
  fuel,
  fun,
  game,
  gas,
  gbp,
  gbx,
  gbyte,
  generic,
  gin,
  glxt,
  gmr,
  gno,
  gnt,
  gold,
  grc,
  grin,
  grs,
  gsc,
  gto,
  gup,
  gusd,
  gvt,
  gxs,
  gzr,
  hight,
  hodl,
  hot,
  hpb,
  hsr,
  ht,
  html,
  huc,
  hush,
  icn,
  icx,
  ignis,
  ilk,
  ink,
  ins,
  ion,
  iop,
  iost,
  iotx,
  iq,
  itc,
  jnt,
  jpy,
  kcs,
  kin,
  klown,
  kmd,
  knc,
  krb,
  lbc,
  lend,
  leo,
  link,
  lkk,
  loom,
  lpt,
  lrc,
  lsk,
  ltc,
  lun,
  maid,
  mana,
  matic,
  mcap,
  mco,
  mda,
  mds,
  med,
  meetone,
  mft,
  miota,
  mith,
  mkr,
  mln,
  mnx,
  mnz,
  moac,
  mod,
  mona,
  msr,
  mth,
  mtl,
  music,
  mzc,
  nano,
  nas,
  nav,
  ncash,
  ndz,
  nebl,
  neo,
  neos,
  neu,
  nexo,
  ngc,
  nio,
  nlc2,
  nlg,
  nmc,
  npxs,
  nuls,
  nxs,
  nxt,
  oax,
  ok,
  omg,
  omni,
  ong,
  ont,
  oot,
  ost,
  ox,
  part,
  pasc,
  pasl,
  pax,
  pay,
  payx,
  pink,
  pirl,
  pivx,
  plr,
  poa,
  poe,
  polis,
  poly,
  pot,
  powr,
  ppc,
  ppp,
  ppt,
  pre,
  prl,
  pungo,
  pura,
  qash,
  qiwi,
  qlc,
  qrl,
  qsp,
  qtum,
  r,
  rads,
  rap,
  rcn,
  rdd,
  rdn,
  ren,
  rep,
  req,
  rhoc,
  ric,
  rise,
  rlc,
  rpx,
  rub,
  rvn,
  ryo,
  safe,
  sai,
  salt,
  san,
  sbd,
  sberbank,
  sc,
  shift,
  sib,
  sin,
  sky,
  slr,
  sls,
  smart,
  sngls,
  snm,
  snt,
  soc,
  spank,
  sphtx,
  srn,
  stak,
  start,
  steem,
  storj,
  storm,
  stq,
  strat,
  sub,
  sumo,
  sys,
  taas,
  tau,
  tbx,
  tel,
  ten,
  tern,
  tgch,
  theta,
  tix,
  tkn,
  tks,
  tnb,
  tnc,
  tnt,
  tomo,
  tpay,
  trig,
  trtl,
  trx,
  tusd,
  tzc,
  ubq,
  unity,
  usd,
  usdc,
  usdt,
  utk,
  veri,
  vet,
  via,
  vib,
  vibe,
  vivo,
  vrc,
  vrsc,
  vtc,
  vtho,
  wabi,
  wan,
  waves,
  wax,
  wgr,
  wicc,
  wings,
  wpr,
  wtc,
  x,
  xas,
  xbc,
  xbp,
  xby,
  xcp,
  xdn,
  xem,
  xin,
  xlm,
  xmcc,
  xmg,
  xmo,
  xmr,
  xmy,
  xp,
  xpa,
  xpm,
  xrp,
  xsg,
  xtz,
  xuc,
  xvc,
  xvg,
  xzc,
  yfi,
  yoyow,
  zcl,
  zec,
  zel,
  zen,
  zest,
  zil,
  zilla,
  zrx
};