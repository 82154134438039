import React from 'react';
import { Container, Typography, Box, Link } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';

export const Privacy = () => {
  const { t } = useTranslation('privacy');
  return (
    <Container>
      <Typography variant='h3' gutterBottom>
        {t('PRIVACY')}
      </Typography>
      <Trans t={t} i18nKey={'PRIVACY_CONTENT'}>
        <Typography paragraph align='justify'>
          RevoluPAY EP S.L. (hereinafter RevoluPAY) is the owner of Revoluex web page or the web you have accessed to.
        </Typography>
        <Typography paragraph align='justify'>
          We are aware that you are worried about how your data is used. Therefore, REVOLUPAY is governed by the highest standards of respect for the fundamental right to the protection of personal data , established in the Charter of Fundamental Rights of the European Union, pursuing the preservation of the confidentiality of our clients' information. , as an essential pillar on which one of our core corporate values is based, trust. Now , we take a step further and reinforce our commitment to your privacy, by guaranteeing the treatment of your data in a loyal, transparent and responsible manner, and incorporate the principles included in Regulation (EU) 2016/679 of the European Parliament and of the Council, of April 27, 2016 (hereinafter, the General Data Protection Regulation) into our internal rules and procedures . This new standard homogenises in the European sphere the protection of people with regard to the treatment and free circulation of their data and establishes a framework of information and protection, in which we want you to feel comfortable. Because we are a company with values, which works with the aim of generating trust in your environment, under the criteria of proximity and transparency, always seeking to provide the best service to your customers. And that is why we explain how we treat your personal data.
        </Typography>
      </Trans>
      <Box marginTop={2}>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('WHO_WE_ARE_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'WHO_WE_ARE_CONTENT'}>
            <Typography paragraph align='justify'>
              RevoluPay is a technological platform specialized in the sale of travel and other online services of the companies of the RevoluGROUP Canada Inc. and other associated companies, as well as the management of payments between them and between them and their users.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('WHY_DO_WE_NEED_YOUR_DATA_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'WHY_DO_WE_NEED_YOUR_DATA_CONTENT'}>
            <Typography gutterBottom variant='h6'>
              Uses with contractual purpose
            </Typography>
            <Typography paragraph align='justify'>
              The first and main reason why we need to process your data is for the provision of the services you have contracted with us and for our own management. This treatment is essential. If we did not, we could not manage your accounts and other services.
            </Typography>
            <Typography gutterBottom variant='h6'>
              Uses for legal or regulatory purposes
            </Typography>
            <Typography paragraph align='justify'>
              In Revoluex, we are obliged by different regulations to treat your data to comply with the obligations that they have. These are standards that establish us, for example, regulatory reporting obligations, measures to prevent money laundering and financing of terrorism or tax controls and reports. In these cases, the treatment that is made of the data is limited to what is necessary to comply with those obligations or responsibilities legally required.
            </Typography>
            <Typography gutterBottom variant='h6'>
              Uses for the purpose of preventing fraud
            </Typography>
            <Typography paragraph align='justify'>
              We also need to treat your data for the prevention of fraud, as well as to ensure the security of both your information and our networks and information systems. As you may have seen, these three types of treatments are essential to maintain your relationship with US. Without them we could not lend you our services
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('WILL_MY_DATA_BE_USED_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'WILL_MY_DATA_BE_USED_CONTENT'}>
            <Typography paragraph align='justify'>
              The above uses are necessary to provide our services but, with your confidence, we would like to offer you much more.
            </Typography>
            <Typography gutterBottom variant='h6'>
              Uses for commercial purposes based on legitimate interest
            </Typography>
            <Typography paragraph align='justify'>
              Unless you have told us, or tell us otherwise, we will send you updates and information about products or services similar to those that you already have contracted.
            </Typography>
            <Typography paragraph align='justify'>
              We will also use your information (account movements, etc.) to personalize your experience with others, for example by showing you your most usual operations first; to offer you the products and services that fit your profile and not bother you with what you do not care about; to apply the benefits and promotions that we have in force and to which you are entitled, because we do not want you to miss any of the advantages of being our client.
            </Typography>
            <Typography paragraph align='justify'>
              We will also use your information (account movements, etc.) to personalize your experience with others, for example by showing you your most usual operations first; to offer you the products and services that fit your profile and not bother you with what you do not care about; to apply the benefits and promotions that we have in force and to which you are entitled, because we do not want you to miss any of the advantages of being our client.
            </Typography>
            <Typography paragraph align='justify'>
              Do not worry. In these treatments we will not use more information than you have given us or that generated by the products contracted during the last year and, if you prefer that we do not, you just have to tell us, in any of our offices, or in the electronic address mis.datos@revolupay.es, or in our mobile applications. For any other commercial use that we want to do, we will ask you before, as you will see below. Remember that one of our core values is trust. 
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('WE_CAN_NOT_HIDE_YOU_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'WE_CAN_NOT_HIDE_YOU_CONTENT'}>
            <Typography paragraph align='justify'>
              Nowadays, there are many possibilities to use the information to get to know you better, give you a better service, be more attentive and always ready to attend to your needs. Therefore, we will ask you for authorization to treat your data a little more than what we told you before. If you have already tried it, or try it in the future, you will not regret it, but do not worry, you do not have to decide now, we will ask you about it in the electronic channels .
            </Typography>
            <Typography gutterBottom variant='h6'>
              Uses based on your consent
            </Typography>
            <Typography paragraph align='justify'>
              Only if you authorize us when we ask you, we would like to treat all the information we have about you to know you better, that is, study your needs to know what new products and services are adjusted to your preferences and analyze the information that allows us have determined in advance what your credit capacity is .
            </Typography>
            <Typography paragraph align='justify'>
              We would also send you product offers from all Group companies and third parties that we think may interest you.
            </Typography>
            <Typography paragraph align='justify'>
              As we have told you, RevoluPAY is a great family, so, when you authorize these treatments you will benefit from the joint work of the REVOLUPAY Group companies in the table below (remember that the list will be updated at all times).
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('WHAT_HAPPENS_WITH_MY_DATA_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'WHAT_HAPPENS_WITH_MY_DATA_CONTENT'}>
            <Typography paragraph align='justify'>
              When you browse our web pages or use our mobile applications, we want to be able to personalize your experience so that it is as exceptional as possible. It is also possible that we want to remind you of our products and offers when you are surfing the internet .
            </Typography>
            <Typography paragraph align='justify'>
              You already know that cookies are used for that. We will inform you at all times of the details of its use in the Cookies Policy, which you will find in all our web pages, as well as in the conditions of use of the mobile applications that you downloads.
            </Typography>
            <Typography paragraph align='justify'>
              There we will describe at each moment what data we can collect, how and for what they are used. In addition , most web browsers allow you to manage your preferences on the use of cookies. Remember that you can adjust at any time the navigator to reject or delete certain cookies according to your criteria.
            </Typography>
            <Typography paragraph align='justify'>
              Also, the privacy settings of the mobile device allow you to manage the processing of your data.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('WHAT_DATA_IS_TREATED_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'WHAT_DATA_IS_TREATED_CONTENT'}>
            <Typography paragraph align='justify'>
              As you can imagine, thanks to the trust you have placed in us, we have a lot of information about you. We have already told you what we use them for and how you can control these uses at any time, but what concrete information are we going to try?
            </Typography>
            <Typography paragraph align='justify'>
              Basically, it is your identification and detail of the professional or work activity, your contact data and financial and socioeconomic data, both those you have provided us and those that are generated from the products or services contracted.
            </Typography>
            <Typography paragraph align='justify'>
              Also, only if you consent when we consult you, we can process data we obtain from the provision of services to third parties when you are the recipient of the service, those obtained from the social networks that you authorize us to consult, the obtained from third parties such as result of data aggregation services that you request, those obtained from the navigations you make through our website, mobile telephony applications and other web pages of REVOLUPAY companies or those obtained from commercial information providers.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('ARE_SENSITIVE_DATA_TREATED_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'ARE_SENSITIVE_DATA_TREATED_CONTENT'}>
            <Typography paragraph align='justify'>
              In general, we do not need to treat certain data of yours that are considered as special categories of data, for example those related to ethnic or racial origin, political opinions, religious convictions or sexual identity. If it is necessary to treat this type of sensitive data, in each case we will request an explicit consent.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('IS_MY_DATA_SECURE_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'IS_MY_DATA_SECURE_CONTENT'}>
            <Typography paragraph align='justify'>
              For us the security of your data is essential, and we assume the obligation and commitment to protect them at all times . Therefore, within this maximum protection standard, we protect them against unauthorized or illicit treatments and against their loss, destruction or accidental damage, and we have implemented the most rigorous information security protocols following the best practices in this area.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('HOW_LONG_SAVE_DATA_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'HOW_LONG_SAVE_DATA_CONTENT'}>
            <Typography paragraph align='justify'>
              We will treat your data as long as the authorization of use that you have granted us remains valid or you have not canceled the contractual or business relationship with us.
            </Typography>
            <Typography paragraph align='justify'>
              We will stop treating them once the authorizations of use that you have given us have been revoked or, if you have not revoked the authorizations but you have ceased to be a client, after six months from the end of the contractual or established business relationships, as long as your data is not necessary for the purposes for which they were collected or treated.
            </Typography>
            <Typography paragraph align='justify'>
              This does not mean that we delete them immediately, since we are obliged by different rules to keep the information for some time (in many cases up to ten years), but according to the regulations, your data will only be kept to comply with these legal obligations, and for the formulation, exercise or defense of claims, during the limitation period of the actions derived from the contractual or business relationships subscribed.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('WHO_DOES_GET_MY_DATA_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'WHO_DOES_GET_MY_DATA_CONTENT'}>
            <Typography paragraph align='justify'>
              In addition to the exchange of commercial information between the companies of the REVOLUPAY Group (of which we previously informed you), on certain occasions we need to share certain information with third parties in order to provide our services , either because a regulation requires them, or because we need the support from specialist companies to help us in our work.
            </Typography>
            <Typography paragraph align='justify'>
              Below we explain to you who we can share your information with, always with maximum security and confidentiality:
            </Typography>
            <Typography gutterBottom variant='h6'>
              Communication of data for compliance with a legal obligation
            </Typography>
            <Typography paragraph align='justify'>
              As we have explained, we collaborate with the authorities, courts and public bodies. If the regulation establishes it, we will share with them the information that they offer us .
            </Typography>
            <Typography gutterBottom variant='h6'>
              Communication of data for the execution of a contractual relationship
            </Typography>
            <Typography paragraph align='justify'>
              Sometimes, we turn to service providers with potential access to personal data. These providers provide adequate and sufficient guarantees in relation to data processing, since we carry out a responsible selection of service providers that incorporate specific requirements in the event that the services involve the processing of personal data.
            </Typography>
            <Typography paragraph align='justify'>
              We may also share your data with third parties that are necessary for the development, compliance and control of the contracts of products and services that you have subscribed with us.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('IS_MY_DATA_TRANSFERRED_OUTSIDE_UE_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'IS_MY_DATA_TRANSFERRED_OUTSIDE_UE_CONTENT'}>
            <Typography paragraph align='justify'>
              The treatment of your data is done, in general, by service providers located within the European Economic Area or in countries that have been declared with an adequate level of protection.
            </Typography>
            <Typography paragraph align='justify'>
              In other cases we guarantee the safety and legitimacy of treatm ent of your data requiring suppliers that have binding corporate rules that guarantee the protection of information similarly to those established European standards, which are covered by the Privacy Shield , in the case of service providers in the USA. UU., Or that they subscribe to the standard clauses of the European Union.
            </Typography>
            <Typography paragraph align='justify'>
              At all times we will ensure that, whoever has your information to help us provide our services, does so with all the guarantees .
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('WHAT_RIGHTS_DO_I_HAVE_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'WHAT_RIGHTS_DO_I_HAVE_CONTENT'}>
            <Typography paragraph align='justify'>
              These are the rights you can exercise in relation to your data:
            </Typography>
            <Typography paragraph align='justify' component='span'>
              <ul>
                <li>Right of access Right to know what data of yours we are dealing with </li>
                <li>Law of revocation of consent Right to withdraw consent at any time when you have given us authorization to process your data</li>
                <li>Right of rectification Right to rectify or complete your data if they are inaccurate</li>
                <li>Right of opposition Right to oppose those treatments based on legitimate interest</li>
                <li>Right of suppression Right to delete your data when it is no longer necessary for the purposes for which it was collected</li>
                <li>Right of limitation Right to limit the treatment of your data (in certain cases, expressly provided in the regulations)</li>
                <li>Portability right Right to give you your data (data processed for the execution of products and services and data that we deal with your consent) so you can transmit them to another person in charge</li>
              </ul>
            </Typography>
            <Typography paragraph align='justify'>
              You can exercise your rights in any of the channels that we put at your disposal:
            </Typography>
            <Typography paragraph align='justify' component='span'>
              <ul>
                <li>Through the options enabled for this purpose in our mobile applications </li>
                <li>At the electronic address mis.datos@revolupay.es</li>
              </ul>
            </Typography>
          </Trans>
        </Box>
      </Box>
    </Container >
  );
}