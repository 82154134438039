import React from 'react';
import {
  Typography,
  Box,
  Grid,
  Container,
  useTheme,
  makeStyles,
  Theme,
  createStyles,
  Card,
  CardContent,
  Link,
  CardActionArea,
  CardMedia,
  CircularProgress
} from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';
import { useApi, useContext } from '../context';
import utils from '../services/utils.service';
import cryptoImage from '../assets/img/crypto.svg';
import revolupayImg from '../assets/img/revolupay.png'
import revoluvipImg from '../assets/img/revoluvip.jpg'
import revoluchargeImg from '../assets/img/revolucharge.png'
import revolutilityImg from '../assets/img/revolutility.png'
import { Cryptocurrency } from '../services/types/cryptocurrency';
import { useHistory } from 'react-router-dom';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    option: {
      '& > span': {
        marginRight: 10,
        fontSize: 18,
      },
    },
    pickDestinationWrapper: {
      maxWidth: 650,
      margin: '0 auto',
      flexGrow: 1
    },
    pickDestinationHeader: {
      backgroundColor: theme.palette.primary.main,
      color: theme.palette.primary.contrastText,
      textAlign: 'center'
    },
    img: {
      maxWidth: '100%',
    },
    featureBox: {
      backgroundColor: theme.palette.grey[200],
      padding: theme.spacing(4, 0, 4, 0),
      marginTop: theme.spacing(6)
    },
    revolupayLogo: {
      width: 400,
      maxWidth: '100%',
    },
    bdeLogo: {
      width: 250,
      maxWidth: '100%',
    }
  })
)

const Crypto = ({ code, rate, onClick }: { code: string, rate: number, onClick: () => void }) => (
  <Box
    display='flex'
    alignItems='center'
    style={{ cursor: 'pointer' }}
    onClick={onClick} >
    <img width={60} src={utils.getCryptoIcon(code)} style={{ marginRight: 10 }} />
    <Box display='flex' flexDirection='column'>
      <Typography variant='h6'>
        {code.toUpperCase()}
      </Typography>
      <Typography variant='subtitle1'>
        {`${utils.currencyFormat(rate)} €UR`}
      </Typography>
    </Box>
  </Box>
)

const Cryptos = () => {
  const classes = useStyles();
  const api = useApi();
  const history = useHistory();
  const [cryptocurrencies, setCryptocurrencies] = React.useState<Cryptocurrency[]>([]);

  React.useEffect(() => {
    utils.runAsync(async () => {
      setCryptocurrencies(await api.getCryptos());
    }, () => {
    });
    const interval = setInterval(() => {
      utils.runAsync(async () => {
        setCryptocurrencies(await api.getCryptos());
      }, () => {
      });
    }, 5000);
    return () => {
      clearInterval(interval)
    };
  }, []);

  return (
    <Box className={classes.featureBox}>
      <Container>
        {cryptocurrencies.length === 0 &&
          <Grid container justify='center'>
            <CircularProgress />
          </Grid>
        }
        {cryptocurrencies.length > 0 &&
          <Grid container spacing={4} justify='space-around'>
            {cryptocurrencies.map(c => (
              <Grid item key={c.code}>
                <Crypto code={c.code} rate={c.rate} onClick={() => {
                  history.push(`/deposit?currency=${c.code}`)
                }} />
              </Grid>
            ))}
          </Grid>
        }
      </Container>
    </Box>
  )
}

export const Home = () => {
  const theme = useTheme();
  const classes = useStyles();
  const { t } = useTranslation('home');

  const context = useContext();

  React.useEffect(() => {
    context.reset();
  }, []);

  const Header = () => {
    return (
      <Container style={{paddingBottom: '120px'}}>
        <Grid container spacing={4} alignItems='center'>
          <Grid item xs={12} sm={6}>
            <Grid container spacing={2} alignContent='center'>
              <Grid item xs={12}>
                <Typography variant='h3' component='h1' align='center'>
                  {t('TITLE')}
                </Typography>
              </Grid>
              <Grid item xs={12}>
              </Grid>
              <Grid item xs={12}>
                <Typography variant='h6' component='h3' align='center'>
                  {t('SUBTITLE')}
                </Typography>
              </Grid>
            </Grid>
          </Grid>
          <Grid item sm={6}>
            <img src={cryptoImage} className={classes.img} />
          </Grid>
        </Grid>
      </Container>
    )
  }




  const Services = () => (
    <Container style={{ marginTop: theme.spacing(6) }}>
      <Grid container spacing={4} justify='center'>
        <Grid item xs={12}>
          <Typography variant={'h4'} align='center'>
            {t('Check out our highlighted services')}
          </Typography>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link underline='none' href='https://www.revoluvip.club/' target='_blank' rel='noopener'>
            <Card>
              <CardActionArea>
                <CardMedia
                  component='img'
                  src={revoluvipImg}
                  title='RevoluVIP'
                />
                <CardContent>
                  <Typography gutterBottom variant='h6'>
                    {t('RevoluVIP')}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component="p">
                    {t('REVOLUVIP_DETAILS')} </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link underline='none' href='https://www.revolucharge.com' target='_blank' rel='noopener'>
            <Card>
              <CardActionArea>
                <CardMedia
                  component='img'
                  src={revoluchargeImg}
                  title='RevoluCHARGE'
                />
                <CardContent>
                  <Typography style={{ textDecoration: 'none !important' }} gutterBottom variant='h6'>
                    {t('RevoluCHARGE')}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component="p">
                    {t('REVOLUCHARGE_DETAILS')}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>
        <Grid item xs={12} sm={6} md={3}>
          <Link underline='none' href='https://www.revolutility.com' target='_blank' rel='noopener'>
            <Card>
              <CardActionArea>
                <CardMedia
                  component='img'
                  src={revolutilityImg}
                  title='RevolUTILITY'
                />
                <CardContent>
                  <Typography gutterBottom variant='h6'>
                    {t('RevolUTILITY')}
                  </Typography>
                  <Typography variant='body2' color='textSecondary' component="p">
                    {t('REVOLUTILITY_DETAILS')}
                  </Typography>
                </CardContent>
              </CardActionArea>
            </Card>
          </Link>
        </Grid>

      </Grid>
    </Container>
  )

  return (
    <>
      <Header />
      <Cryptos />
      <Services />
    </>
  )
}