import { Config } from '../config';
import { Auth } from './auth.service';
import { Chain } from './types/chain.type';
import { Cryptocurrency } from './types/cryptocurrency';
import { Me } from './types/me.type';
import { Topup, Topup } from './types/topup.type';
import { TopupErrors } from './types/topupErrors.enum';
import { Wallet } from './types/wallet.type';

export class Api {

  constructor(
    private readonly config: Config,
    private readonly auth: Auth,
  ) { }


  async topup({ amount, chain, currency }: { amount: number; chain: string; currency: string }): Promise<{ success: boolean; wallet: Wallet | undefined; topup: Topup | undefined; error: TopupErrors | undefined }> {
    const response = await fetch(
      this.config.API_BASE_URL + '/topups',
      {
        method: 'POST',
        headers: {
          Authorization: 'Bearer ' + this.auth.getAccessToken(),
          'content-type': 'application/json'
        },
        body: JSON.stringify({
          amount,
          crypto_currency_code: currency,
          crypto_chain_code: chain
        })
      }
    );
    if (response.ok) {
      const json = await response.json();
      return {
        success: true,
        error: undefined,
        wallet: json.wallet,
        topup: json.topup
      };
    } else if (response.status === 409) {
      return {
        success: false,
        error: (await response.json()).error,
        wallet: undefined,
        topup: undefined
      };

    } else {
      throw new Error('Got wrong status code while creating topup');
    }

  }

  async getCryptos(): Promise<Cryptocurrency[]> {
    const response = await fetch(this.config.API_BASE_URL + '/cryptocurrencies');
    if (response.ok) {
      return (await response.json()).cryptocurrencies;
    } else {
      throw new Error('Got wrong status code while fetching currencies');
    }
  }

  async getChains(code: string): Promise<Chain[]> {
    const response = await fetch(this.config.API_BASE_URL + `/cryptocurrencies/${code}/chains`);
    if (response.ok) {
      return (await response.json()).chains;
    } else {
      throw new Error('Got wrong status code while fetching chains');
    }
  }

  async me(): Promise<Me> {
    const response = await fetch(
      this.config.API_BASE_URL + '/me',
      {
        headers: {
          Authorization: 'Bearer ' + this.auth.getAccessToken(),
        }
      }
    );
    if (response.ok) {
      return (await response.json());
    } else {
      throw new Error('Got wrong status code while fetching me');
    }
  }

  async getTopups({
    limit = 50, offset = 0, order = 'desc' }: {
      limit: number, offset: number, order: 'asc' | 'desc'
    }): Promise<{ topups: Topup[], count: number }> {
    const params = new URLSearchParams();
    params.append('limit', limit.toString(10));
    params.append('offset', offset.toString(10));
    params.append('order', order);
    const response = await fetch(
      this.config.API_BASE_URL + '/topups?' + params.toString(),
      {
        headers: {
          Authorization: 'Bearer ' + this.auth.getAccessToken(),
        }
      }
    );
    if (response.ok) {
      return await response.json();
    } else {
      throw new Error('Got wrong status code while fetching topups');
    }
  }

  async getWalletForTopup(topupId: number): Promise<Wallet> {
    const response = await fetch(
      this.config.API_BASE_URL + `/topups/${topupId.toString(10)}/wallet`,
      {
        headers: {
          Authorization: 'Bearer ' + this.auth.getAccessToken(),
        }
      }
    );
    if (response.ok) {
      return (await response.json()).wallet;
    } else {
      throw new Error('Got wrong status code while fetching topup wallet');
    }

  }


}