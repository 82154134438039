import React from 'react';
import { Container, Typography, Box, Link } from '@material-ui/core';
import { useTranslation, Trans } from 'react-i18next';

export const TermsContent = () => {
  const { t } = useTranslation('terms');
  return (
    <>
      <Typography variant='h3' gutterBottom>
        {t('TERMS')}
      </Typography>
      <Trans t={t} i18nKey={'TERMS_CONTENT'}>
        <Typography paragraph align='justify'>
          RevoluPAY EP SL (hereinafter, RevoluPAY ) is the owner of the Revoluex portal and the website you have accessed. RevoluPAY is the owner of the Revoluex trade name.
        </Typography>
        <Typography paragraph align='justify'>
          Access to the portal or to any of the Revoluex – RevoluPAY websites and to the information about any of the products and services contained therein implies acceptance of the general conditions provided in this Legal Notice. Therefore, you must read its content carefully if you wish to access and make use of the information and services offered on the portal or on any of the Revoluex - RevoluPAY websites.
        </Typography>
      </Trans>
      <Box marginTop={2}>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('PROVISION_OF_SERVICE_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'PROVISION_OF_SERVICE_CONTENT'}>
            <Typography paragraph align='justify'>
              To use the Services, you must have an account with RevoluPay in advance. During the RevoluEx recharge process, you may be required to provide personal information, such as your email address or phone number, and financial information. All information you provide to us as a customer will be treated under our Privacy Policy.
            </Typography>
            <Typography paragraph align='justify'>
              You acknowledge that the information you provide during the recharge process is true, accurate and complete. If this information is not correct, it will be impossible for us to provide the service and notify you of the "Recharge" status. It is your responsibility to inform us of any changes to such information.
            </Typography>
            <Typography paragraph align='justify'>
              You are solely responsible for any activity that takes place when your email address, phone number is used, including unauthorized or wrongful use of your Wallet. You must not disclose your private key, either directly or indirectly, to any third party. Safeguarding your private key of your Wallet is your responsibility.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('USE_AND_COST_OF_SERVICES_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'USE_AND_COST_OF_SERVICES_CONTENT'}>
            <Typography paragraph align='justify'>
              You agree to use the Applications and Services according to these Terms and comply with any applicable regulations in conjunction with the provisions set forth herein.
            </Typography>
            <Typography paragraph align='justify'>
              You may not use the Applications or Services: (i) in violation of any law, statute, rule, or regulation; or (ii) in connection with any illegal, fraudulent, offensive, violent, immoral or indecent activity; or (iii) in any manner that encourages, promotes, facilitates or instructs others in any illegal, fraudulent, offensive, violent, immoral or indecent activity.
            </Typography>
            <Typography paragraph align='justify'>
              You must provide certain information regarding the Services in the Applications. It is your responsibility to ensure that you enter the information correctly.
            </Typography>
            <Typography paragraph align='justify'>
              The total amount (including applicable fees) that you will be asked to pay will be clearly displayed on the Applications before you are asked to confirm the transaction, execution of which at that time is entirely optional. The reload will be received in a different currency than the one you chose when you made the payment, therefore, the amount to be charged will be subject to variations in the exchange rate of the Cryptospeed/EUR at the time of payment and a conversion rate will be applied. You may also be charged a fee for secure online processing in connection with any Product you purchase through the Applications.
            </Typography>
            <Typography paragraph align='justify'>
              RevoluPay will provide the Services once payment has been successfully made. You acknowledge that you will lose the right to cancel the reload of your Wallet once RevoluPay has completed the Services. Therefore, you will not be entitled to claim any refunds under the European Union's 2013 regulation on information, cancellation, and other consumer rights. Please note that Applications limit the number and value of refills that can be made or received in a specific time period (e.g., per day, week or month). Other limitations and exclusions may apply from time to time in connection with the use of the Applications or the purchase of the Products.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('THE_PROCESS_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'THE_PROCESS_CONTENT'}>
            <Typography paragraph align='justify'>
              You must ensure that all instructions you provide to RevluPay through the Applications are accurate, complete, and correct. In particular, you must correctly identify yourself on the RevoluEx platform in order to access the Wallet to which the refill is to be sent. You must ensure that any instructions you are shown again for confirmation are correct, accurate, and trustworthy. All confirmed instructions are final and binding on you. RevoluPay and/or its service providers will not be responsible for any consequences related to incorrect, inaccurate, or false information you provide. You are solely responsible for the consequences of not providing correct, accurate, and truthful information or not correcting inaccurate information before the final confirmation.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('TRANSACTIONS_MADE_WITH_CRYPTOCURRENCIES_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'TRANSACTIONS_MADE_WITH_CRYPTOCURRENCIES_CONTENT'}>
            <Typography paragraph align='justify'>
              You can reload your RevoluPay Wallets with the following payment methods: Bitcoin, Litecoin, Ethereum, Monero, Chainlink, Ripple, Stellar Lumens, Huobi Token, or any other Cryptcoin available in the Applications.
            </Typography>
            <Typography paragraph align='justify'>
              After receiving a complete and correct application from you to perform the Services, RevoluEx will show you the Wallet and amounts that you will need to transfer into the selected Krypton currency. Remember that it is your responsibility to choose correctly for the chosen cryptocurrency, the Wallet of origin, correctly enter the Wallet of destination and send the requested amount, excluding any fees. For the payment process, you will have 15 minutes to complete the transaction and send the funds. Once you have received confirmation of the receipt of the amount, an electronic request will be sent again to accept the transaction and the amount corresponding to the balance in Eur will be transferred to your RevoluPay Wallet.
            </Typography>
            <Typography paragraph align='justify'>
              The process of sending the Cryptocurrencies is entirely outside of RevoluPay's control and totally exempt from its responsibility. This may be delayed according to the saturation situation of the corresponding Blockchain at that moment. RevoluPay has no obligation to take any other authentication or identity verification measures beyond those required by applicable regulations or those deemed appropriate and sufficient by RevoluPay to protect against fraud or money laundering and to maintain the security and proper use of the Applications to comply with internal policies. You alone are responsible for all charges made after you have appropriately authenticated or verified your identity.
            </Typography>
            <Typography paragraph align='justify'>
              RevoluPay will be liable for non-execution or poor execution of the Services purchased through the Applications, provided that you act in accordance with these Terms and make proper use of the Applications as directed by RevoluPay, and there is no misrepresentation, fraud, or negligence on your part. Such liability, if any, is strictly limited to the amount of the Services not performed or improperly performed.
            </Typography>
            <Typography paragraph align='justify'>
              Revoluex - RevoluPAY reserves the right to return the charge of the transaction to the Wallet of origin, therefore, to reverse the transaction if it is determined that the beneficiary does not comply with the legal requirements foreseen, in particular with the Law 10/2010 of 28 April on the Prevention of Money Laundering and Financing of Terrorism (BC/FT).
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('PROMOTIONS_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'PROMOTIONS_CONTENT'}>
            <Typography paragraph align='justify'>
              In the event of promotions offered, the particular conditions of the promotion itself should be consulted. Any promotion is subject to additional terms and conditions which are displayed clearly (including date of start / end of the promotion) in the application itself / website Revoluex / RevoluPAY additionally may request further information to the email address: contact@revolupay.es
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('VALIDITY_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'VALIDITY_CONTENT'}>
            <Typography paragraph align='justify'>
              The information contained in these pages is current as of the date of the last update of the application / web.
            </Typography>
            <Typography paragraph align='justify'>
              These conditions are always valid from the date of their last update. Revoluex - RevoluPAY reserves the right to modify them at any time, in which case they will come into force after its publication and shall apply to all users of the portal or web sites from that date.
            </Typography>
            <Typography paragraph align='justify'>
              The content of any of the web pages, especially informational and advertising references, unless expressly stated otherwise, does not constitute a binding offer.
            </Typography>
            <Typography paragraph align='justify'>
              Revoluex - RevoluPAY reserves the right to modify or partially or totally the current contents when it deems appropriate, as well as prevent or restrict access temporarily or permanently.
            </Typography>
            <Typography paragraph align='justify'>
              Revoluex - RevoluPAY can include in any of its websites third-party content or links, always with the permission of their owners, such as social networking and other information. In any of these cases, Revoluex - RevoluPAY will not be responsible for the pages and content of third parties and its operation and availability.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('NAVIGATION_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'NAVIGATION_CONTENT'}>
            <Typography paragraph align='justify'>
              Simply browsing through websites Revoluex - RevoluPAY is free and does not require prior registration of the user. However, access, contracting or use of some products or services may require the user to register on the web / application for the service. In this case, each of said products and services will be governed by its own specific conditions, without prejudice to the conditions established in this document.
            </Typography>
            <Typography paragraph align='justify'>
              In some of the portals or websites the user will be offered the possibility of personalizing the pages, including different wallpapers or that they can configure access to their favorite products and services and / or certain , making navigation and the location of resources used are efficient, functional and therefore adapted to the tastes and uses of each user. This personalization obeys the user's preferences and, therefore, corresponds to their will, Revoluex-RevoluPAY is not responsible for this personalization.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('SECURITY_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'SECURITY_CONTENT'}>
            <Typography paragraph align='justify'>
              The Revoluex - RevoluPAY website and the websites are provided with the necessary safety and quality certificates, seals or accreditations to offer a safe environment to users.
            </Typography>
            <Typography paragraph align='justify'>
              However, you should consult and carefully read the safety recommendations Revoluex - RevoluPAY placed on its website available to all users , in particular concerning the calculation of currency changes affecting shipments of money it is ordered in each operation that you agree to perform by the system .
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('PRIVACY_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'PRIVACY_CONTENT'}>
            <Typography paragraph align='justify'>
              Revoluex - RevoluPAY fully complies with current legislation on protection of personal data and specific confidentiality commitments of the activity carried out.
            </Typography>
            <Typography paragraph align='justify'>
              Revoluex - RevoluPAY has adopted the technical measures necessary to maintain the required level of security, according to the nature of the personal data processed and the circumstances of the treatment, to avoid, always as far as possible its alteration, the loss, its treatment or unauthorized access or third parties .
            </Typography>
            <Typography paragraph align='justify'>
              When filling in a form in which personal data is collected is requested, it will inform the client and beneficiary of the operation of l purpose for which the data, the identity and address of the person responsible for the file and the right are collected of the user to exercise the rights of access, rectification, cancellation / opposition to the processing and limitation processing, as well as your right portability of your data.
            </Typography>
            <Typography paragraph align='justify'>
              The personal data collected will only be processed and / or assigned for the stated purpose, and always with the consent of the user or client ordering the operation.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('COOKIES_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'COOKIES_CONTENT'}>
            <Typography paragraph align='justify'>
              The website of Revoluex - RevoluPAY contains cookies, which are small data files that are stored on the computer of the user or client and that allow our systems to remember the characteristics or browsing preferences on our web pages that can be used to personalize your access on subsequent visits, make browsing safer, collect statistical information about the browsing carried out or know the preferences of users.
            </Typography>
            <Typography paragraph align='justify'>
              Revoluex – RevoluPAY’s Cookies policy  is subject to the applicable rules of the Community European and Spain, in relation to the processing of personal data and the protection of privacy in the electronic communications sector.
            </Typography>
            <Typography paragraph align='justify'>
              For this reason , Revoluex - RevoluPAY will tell you about the cookies used on each of their websites ( Policy cookies ) and, when necessary, ask for your consent expressly to use them .
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('MOBILE_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'MOBILE_CONTENT'}>
            <Typography paragraph align='justify'>
              Some of the web pages of Revoluex - RevoluPAY are adapted for use on mobile devices, so it is possible that the presentation and content of the different mobile applications do not coincide or be precise with those of the portal or of the different websites .
            </Typography>
            <Typography paragraph align='justify'>
              Likewise, some of the web pages adapted for mobile devices or applications may have geolocation functions, to determine when the user activates it, the location of said device at all times. In this way, you can access the services that require geolocation and that Revoluex - RevoluPAY has available.
            </Typography>
            <Typography paragraph align='justify'>
              Access to the aforementioned services will require the activation by the user of the geolocation function on their mobile device, which they can deactivate at any time.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('INTELLECTUAL_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'INTELLECTUAL_CONTENT'}>
            <Typography paragraph align='justify'>
              The portal and the websites of RevoluPAY ( including Revoluex ), l pages as comprising and the information or elements contained therein, including texts, documents, photographs, drawings, graphics, databases, software and logos , trademarks, trade names, or other distinctive signs, protected by intellectual or industrial property rights, of which RevoluPAY or its Group companies are legitimate licensees.
            </Typography>
            <Typography paragraph align='justify'>
              Revoluex - RevoluPAY gives no guarantee regarding the legality of the information or elements contained on the websites of Revoluex - RevoluPAY in the event that the property is not applicable to RevoluPAY or companies your group.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('PROHIBITED_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'PROBITED_CONTENT'}>
            <Typography paragraph align='justify'>
              Any type of exploitation, including any type of reproduction, distribution, assignment to third parties, public communication and transformation, through any type of medium and medium of the aforementioned works, creations and distinctive signs without the prior and express authorization of their respective owners it is expressly prohibited . Failure to comply with this prohibition may constitute a crime punishable by current legislation, in relation to the crime of theft / misappropriation.
            </Typography>
            <Typography paragraph align='justify'>
              However, at your own risk, the user may download or make copies of such items for personal use only, provided they do not infringe any intellectual or industrial property Revoluex - RevoluPAY . In any case, this use implies that you cannot alter, modify or erase them totally or partially, altering their integrity in a way that could harm you. In no case, this will mean an authorization or license on the property rights of Revoluex - RevoluPAY or the companies of its Group.
            </Typography>
            <Typography paragraph align='justify'>
              It is prohibited, except in cases where it is expressly authorized by RevoluPAY, the final owner of the product / brand, to establish links, hyperlinks or links, from third party portals or websites to RevoluPAY web pages other than the main page of its portal, as well as to present the RevoluPAY web pages or the information contained therein in frames or frames, distinctive signs, trademarks or social or commercial names of another person, company or entity.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('FINANCIAL_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'FINANCIAL_CONTENT'}>
            <Typography paragraph align='justify'>
              The website of Revoluex - RevoluPAY or the websites do not provide any type of currency exchange recommendation, nor investment, legal advice, tax advice or any other type of advice , especially regarding the associated currency exchange to the specific operation of sending money .
            </Typography>
            <Typography paragraph align='justify'>
              The information is general in scope and in no case does it take into account specific or particular investment criteria or objectives, financial situation or currency exchange. Therefore, the information contained should not be used to make investments or make decisions regarding currency exchange, in particular.
            </Typography>
            <Typography paragraph align='justify'>
              Data change currency on the form of acceptance of the operation and shall apply to the time when the load is expressly accepted in the wallet of the consideration in CCU of the balance of your wallet , validation by the coordinate corresponding operation It corresponds to the total acceptance of the operation in all its terms expressed in the form provided by the user to Revoluex - RevoluPAY .
            </Typography>
            <Typography paragraph align='justify'>
              In the case of traded securities, any subscription, purchase or sale decision must be made taking into account public information.
            </Typography>
            <Typography paragraph align='justify'>
              The content of the web pages does not constitute an offer to sell shares in the United States of America, or in any other negotiated market .
            </Typography>
            <Typography paragraph align='justify'>
              The shares may not be offered, sold or subscribed in the United States of America in the absence of their registration or of a registration exemption established in the Securities Market Law of the United States of America ("United States Securities Law 1933 ").
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('RESPONSIBILITIES_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'RESPONSIBILITIES_CONTENT'}>
            <Typography paragraph align='justify'>
              RevoluPAY does not guarantee continuous access, or the correct viewing, downloading or use of the elements and information contained in the RevoluPAY web pages , including Revoluex , which may be hampered or interrupted by factors or circumstances beyond its control.
            </Typography>
            <Typography paragraph align='justify'>
              RevoluPAY is not responsible for the information and other content integrated in spaces or web pages of third parties accessible from RevoluPAY web pages (including Revoluex ) through links, hyperlinks or links, or information and other content integrated in spaces or web pages of third parties from which Access through links, hyperlinks or links to the RevoluPAY portal or any of its web pages, or the information and content of any third party website that appears under the appearance or distinctive signs of RevoluPAY , unless it is expressly authorized by the latter.
            </Typography>
            <Typography paragraph align='justify'>
              RevoluPAY and its third party information providers do not assume any responsibility in relation to the information, content of all kinds, products and services offered or provided through the RevoluPAY web pages by third parties and / or other entities, even if they belong to the same Economic Group , and, especially, for damages of any kind that, linked to the foregoing, may be caused by:
            </Typography>
            <Typography paragraph align='justify'>
              1. absence or deficiencies in the information provided to users or in its veracity, accuracy and sufficiency.
            </Typography>
            <Typography paragraph align='justify'>
              2. breach or defective or unpunctual fulfillment of contracts or pre-contractual relationships.
            </Typography>
            <Typography paragraph align='justify'>
              3. the breach of the obligations incumbent on the information society service providers;
            </Typography>
            <Typography paragraph align='justify'>
              4. infringement of the rights of consumers and users.
            </Typography>
            <Typography paragraph align='justify'>
              5. infringement of intellectual and industrial property rights; perform acts of unfair competition or illegal advertising.
            </Typography>
            <Typography paragraph align='justify'>
              6. infringement of the data protection right; professional secrecy and the rights of honor, personal and family privacy and the image of people.
            </Typography>
            <Typography paragraph align='justify'>
              7. in general, the breach of any law, customs or code of conduct that results from the application and
            </Typography>
            <Typography paragraph align='justify'>
              8. any decision made in accordance with the information provided through the Revoluex - RevoluPAY portal.
            </Typography>
            <Typography paragraph align='justify'>
              Neither Revoluex - RevoluPAY , nor the third party information providers assume any responsibility for damages, losses, claims or expenses caused by:
            </Typography>
            <Typography paragraph align='justify'>
              1. interferences, interruptions, failures, omissions, telephone breakdowns, delays, blockages or disconnections in the operation of electronics. System, motivated by deficiencies, overloads and errors in telecommunications lines and networks, or by any other cause beyond the control of RevoluPAY nor;
            </Typography>
            <Typography paragraph align='justify'>
              2. illegitimate interference through the use of malicious programs of any kind and by any means of communication, such as computer viruses or any other;
            </Typography>
            <Typography paragraph align='justify'>
              3. Improper or inappropriate use of the web pages of RevoluPAY - Revoluex or;
            </Typography>
            <Typography paragraph align='justify'>
              4. security or navigation errors caused by a malfunction of the browser or using non-updated versions of it.
            </Typography>
          </Trans>
        </Box>
        <Box>
          <Typography variant='h5' gutterBottom>
            {t('LEGISLATION_TITLE')}
          </Typography>
          <Trans t={t} i18nKey={'LEGISLATION_CONTENT'}>
            <Typography paragraph align='justify'>
              These general conditions will be governed by current Spanish legislation, as well as the competent Spanish Courts, specifically the Courts of Barcelona, ​​for any discussion, provided that the internal friendly way of RevoluPAY has been previously exhausted.
           </Typography>
          </Trans>
        </Box>
      </Box>
    </>
  );
}

export const Terms = () => {
  return (
    <Container>
      <TermsContent />
    </Container >
  );
}