import { Context } from '../context';
import { Cookies } from 'react-cookie';
import { Config } from '../config';
import jwt from 'jsonwebtoken';
import moment from 'moment';

export class Auth {

  private readonly cookies = new Cookies();
  private accessToken?: string;

  constructor(
    private readonly config: Config,
    private readonly context: Context,
  ) { }

  private setAccessToken(accessToken: string, keepSignedIn: boolean) {
    this.accessToken = accessToken;
    this.cookies.set(
      'accessToken',
      accessToken,
      {
        path: '/',
        maxAge: keepSignedIn ? 3600 * 24 * 7 : undefined,
        sameSite: 'strict',
        // secure: true
      }
    );
  }

  public getAccessToken(): string {
    let accessToken: string | undefined;
    if (this.accessToken) {
      accessToken = this.accessToken;
    } else {
      accessToken = this.cookies.get('accessToken');
    }
    if (accessToken && this.isAccessTokenValid(accessToken)) {
      return accessToken;
    } else if (accessToken) {
      this.logout();
    }
    throw new Error('No valid access token');
  }

  public attemptCookieLogin() {
    const accessToken = this.cookies.get('accessToken')
    if (accessToken && this.isAccessTokenValid(accessToken)) {
      this.accessToken = accessToken;
      this.context.setData({ isSignedIn: true });
    }
  }

  async login(
    username: string,
    password: string,
    keepSignedIn: boolean
  ): Promise<{ success: boolean, error: 'WRONG_CREDENTIALS' | 'NO_KYC' | undefined }> {
    const response = await fetch(
      this.config.API_BASE_URL + '/login',
      {
        method: 'POST',
        headers: {
          'content-type': 'application/json'
        },
        body: JSON.stringify({ username, password })
      }
    );
    if (response.ok) {
      const token = (await response.json()).token
      this.setAccessToken(token, keepSignedIn);
      this.context.setData({ isSignedIn: true });
      return { success: true, error: undefined };
    } else {
      if (response.status === 409) {
        return { success: false, error: 'NO_KYC' };
      } else {
        return { success: false, error: 'WRONG_CREDENTIALS' };
      }
    }
  }

  logout() {
    this.accessToken = undefined;
    this.cookies.remove('accessToken');
    this.context.setData({ isSignedIn: false });
  }

  private isAccessTokenValid(accessToken: string): boolean {
    try {
      const exp = jwt.decode(accessToken).exp;
      return moment(exp * 1000).isAfter(moment());
    } catch (e) {
      return false;
    }
  }

}