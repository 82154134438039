import React from 'react';
import Paper from '@material-ui/core/Paper';
import { Box, CircularProgress, Grid, List, ListItem, ListItemText, Typography } from '@material-ui/core';
import utils from '../services/utils.service';
import { useApi } from '../context';
import { useTranslation } from 'react-i18next';

export const Limits = () => {
  const api = useApi();
  const { t } = useTranslation(['account', 'deliveryMethod']);
  const [amount, setAmount] = React.useState<number | undefined>(undefined);
  const [of, setOf] = React.useState<number | undefined>(undefined);
  const [loading, setLoading] = React.useState(true);

  React.useEffect(() => {
    utils.runAsync(async () => {
      setLoading(true);
      const me = await api.me();
      setAmount(me.limit.amount);
      setOf(me.limit.of);
    }, () => {
      setLoading(false);
    });
  }, []);

  return (
    <>
      <Paper>
        <Box paddingTop={2} paddingLeft={1} paddingBottom={1}>
          <Typography variant="h5">
            {t('LIMITS')}
          </Typography>
        </Box>
        {loading &&
          <Grid item container justify='center'>
            <Box padding={4}>
              <CircularProgress />
            </Box>
          </Grid>
        }
        {!loading &&
          <>
            <Box paddingLeft={1} paddingBottom={2}>
              <List disablePadding>
                <ListItem>
                  <ListItemText
                    primary={t('MONTHLY_LIMIT')}
                    secondary={
                      <>
                        <Typography variant='body2'>
                          {
                            t(
                              'MONTHLY_LIMIT_DESC',
                              {
                                amount: utils.currencyFormat(amount ? amount : 0), of: utils.currencyFormat(of ? of : 0)
                              }
                            )
                          }
                        </Typography>
                      </>
                    }
                  />
                </ListItem>
              </List>
              <Typography variant='subtitle2'>{t('LIMITS_INCREASE_DESC')}</Typography>

            </Box>
          </>
        }
      </Paper>
    </>
  );
}