import React from 'react';
import { Grid, Typography, makeStyles, TextField, InputAdornment, CircularProgress } from '@material-ui/core';
import Autocomplete from '@material-ui/lab/Autocomplete';
import { useApi, useContext } from '../context';
import utils from '../services/utils.service';
import { useHistory, useLocation } from 'react-router-dom';
import { DepositWizard, DepositWizardStep } from './wizard';
import { useTranslation } from 'react-i18next';
import { Cryptocurrency } from '../services/types/cryptocurrency';
import { Chain as ChainType } from '../services/types/chain.type';
import moment from 'moment';
import { Login } from '../components/login';
import { AlertDialog } from '../components/alertDialog';


const useStyles = makeStyles({
  option: {
    fontSize: 15,
    '& > span': {
      marginRight: 10,
      fontSize: 18,
    },
  },
});

export const Chain = () => {
  const api = useApi();
  const { t, i18n } = useTranslation('chain');
  moment.locale(i18n.language);
  const context = useContext();
  const history = useHistory();
  const location = useLocation();
  let didLoadCurrencies = false;

  const { currency: queryCurrency } = (() => {
    if (location.search) {
      const query = new URLSearchParams(location.search);
      return {
        currency: query.get('currency') ? query.get('currency') : null,
      };
    } else {
      return {
        currency: undefined,
      }
    }
  })();

  const [amount, setAmount] = React.useState(100);
  const [textAmount, setTextAmount] = React.useState('');

  const [currencies, setCurrencies] = React.useState<Cryptocurrency[]>([]);
  const [selectedCurrency, selectCurrency] = React.useState<null | Cryptocurrency>(null);
  const [chains, setChains] = React.useState<ChainType[]>([]);
  const [selectedChain, selectChain] = React.useState<ChainType | null>(null);
  const [loadingChains, setLoadingChains] = React.useState(false);
  const [loadingCurrencies, setLoadingCurrencies] = React.useState(false);
  const [creatingTopup, setCreatingTopup] = React.useState(false);
  const [error, setError] = React.useState<string | null>(null);

  const isLoading = loadingChains || loadingCurrencies;

  const classes = useStyles();

  const createTopup = () => {
    if (selectChain && selectCurrency) {
      setCreatingTopup(true);
      utils.runAsync(async () => {
        const topup = await api.topup({ amount, chain: selectedChain!.code, currency: selectedCurrency!.code })
        if (topup.success) {
          context.setData({ topup: { data: topup.topup!, wallet: topup.wallet! } });
          history.push('/deposit/wallet');
        } else {
          loadCurrencies();
          setError(topup.error!);
        }
      }, () => {
        setCreatingTopup(false);
      })
    }
  }

  React.useEffect(() => {
    selectChain(null);
    if (selectedCurrency) {
      utils.runAsync(async () => {
        setLoadingChains(true);
        setChains(await api.getChains(selectedCurrency.code));
      }, () => {
        setLoadingChains(false);
      });
    } else {
      setChains([]);
    }
  }, [selectedCurrency]);

  React.useEffect(() => {
    setTextAmount(amount.toString(10));
  }, [amount]);

  React.useEffect(() => {
    setTextAmount(amount.toString(10));
    if (selectedCurrency) {
      const foundCurrency = currencies.find(c => selectedCurrency.code === c.code);
      selectCurrency(foundCurrency ? foundCurrency : null);
    }
  }, [currencies])

  React.useEffect(() => {
    if (!isNaN(parseFloat(textAmount))) {
      setAmount(parseFloat(textAmount));
    }
  }, [textAmount]);

  React.useEffect(() => {
    if (selectedChain) {
      setAmount(utils.ceil(selectedChain.min_amount, selectedChain.precision));
    }
  }, [selectedChain]);

  React.useEffect(() => {
    if (selectedChain === null && chains.length === 1) {
      selectChain(chains[0]);
    }
  }, [chains]);

  React.useEffect(() => {
    loadCurrencies();
  }, []);

  const loadCurrencies = () => {
    utils.runAsync(async () => {
      if (!didLoadCurrencies) {
        setLoadingCurrencies(true);
      }
      const tempCurrencies = await api.getCryptos();
      setCurrencies(tempCurrencies);
      if (!didLoadCurrencies && queryCurrency) {
        const foundCurrency = tempCurrencies.find(c => c.code.toLowerCase() === queryCurrency.toLowerCase());
        if (foundCurrency) {
          selectCurrency(foundCurrency);
        }
      }
      didLoadCurrencies = true;
    }, (e) => {
      setLoadingCurrencies(false);
    });
  }

  return (
    <>
      <AlertDialog title={t('ERROR_TITLE')} message={error ? t(error) : ''} open={error !== null} onClose={() => { setError(null) }} />
      <DepositWizard
        canGoNext={selectedChain !== null && !creatingTopup && !isLoading}
        loading={creatingTopup}
        next={createTopup}
        step={DepositWizardStep.CHAIN}
        showButtons={context.data.isSignedIn}
      >
        <Login paper={false}>
          <Grid container spacing={2}>
            <Grid item xs={12}>
              <Typography>
                {t('INSTRUCTIONS')}
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {!loadingCurrencies &&
                <Autocomplete
                  onChange={(event, value) => {
                    selectCurrency(value);
                  }}
                  disabled={isLoading}
                  value={selectedCurrency}
                  options={currencies}
                  classes={{
                    option: classes.option,
                  }}
                  autoHighlight
                  getOptionSelected={(option, value) => option.code === value.code}
                  getOptionLabel={(option) => option.code.toUpperCase()}
                  renderOption={(option) => (
                    <React.Fragment>
                      <img width={25} src={utils.getCryptoIcon(option.code)} style={{ marginRight: 5 }} />
                      <span style={{ marginLeft: 10 }}>{option.code.toUpperCase()}</span>
                    </React.Fragment>
                  )}
                  renderInput={(params) => (
                    <TextField
                      {...params}
                      label={t('CHOOSE_CRYPTOCURRENCY')}
                      variant='outlined'
                      InputProps={{
                        ...params.InputProps,
                        startAdornment: selectedCurrency ? (
                          <img width={25} src={utils.getCryptoIcon(selectedCurrency.code)} style={{ marginRight: 5 }} />
                        ) : undefined
                      }}
                      inputProps={{
                        ...params.inputProps,
                        autoComplete: 'chrome-off',
                      }}
                    />
                  )}
                />
              }
            </Grid>
            {selectedCurrency && !loadingChains && chains.length > 1 &&
            <Grid item xs={12}>
              <Autocomplete
                onChange={(event, value) => {
                  selectChain(value);
                }}
                value={selectedChain}
                disabled={isLoading}
                getOptionSelected={(option, value) => option.code === value.code}
                options={chains}
                classes={{
                  option: classes.option,
                }}
                autoHighlight
                getOptionLabel={(option) => option.code.toUpperCase()}
                renderOption={(option) => option.code.toUpperCase()}
                renderInput={(params) => (
                  <TextField
                    {...params}
                    label={t('CHOOSE_CHAIN')}
                    variant='outlined'
                    inputProps={{
                      ...params.inputProps,
                      autoComplete: 'chrome-off',
                    }}
                  />
                )}
              />
            </Grid>
            }
            {selectedCurrency && selectedChain &&
              <Grid item xs={12}>
                <Grid container spacing={2}>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label={t('DEPOSIT_AMOUNT')}
                      type='number'
                      variant='outlined'
                      value={textAmount}
                      onChange={(event) => {
                        setTextAmount(event.target.value);
                      }}
                      onBlur={(event) => {
                        let amount = Number(event.target.value)
                        if (!isNaN(amount) && selectedCurrency) {
                          amount = utils.truncate(amount, selectedChain.precision);
                          if (amount < selectedChain.min_amount) {
                            setTextAmount(utils.ceil(selectedChain.min_amount, selectedChain.precision).toString(10));
                          } else if (amount > selectedChain.max_amount) {
                            setTextAmount(utils.truncate(selectedChain.max_amount, selectedChain.precision).toString(10));
                          } else {
                            setTextAmount(amount.toString(10));
                          }
                        }
                      }}
                      InputProps={{
                        endAdornment: <InputAdornment position='end'>{selectedCurrency!.code.toUpperCase()}</InputAdornment>,
                      }}
                    />
                  </Grid>
                  <Grid item xs={6}>
                    <TextField
                      fullWidth={true}
                      label={t('EXCHANGE_AMOUNT')}
                      variant='outlined'
                      value={utils.round(amount * selectedCurrency.rate).toString()}
                      onChange={(event) => {
                        const amount = Number(event.target.value);
                        const convertedAmount = amount / selectedCurrency.rate;
                        if (!isNaN(amount) && selectedCurrency) {
                          setAmount(convertedAmount);
                        }
                      }}
                      onBlur={(event) => {
                        const amount = Number(event.target.value)
                        const convertedAmount = amount / selectedCurrency.rate;
                        if (convertedAmount < selectedChain.min_amount) {
                          setAmount(utils.ceil(selectedChain.min_amount, selectedChain.precision));
                        } else if (convertedAmount > selectedChain.max_amount) {
                          setAmount(utils.truncate(selectedChain.max_amount, selectedChain.precision));
                        } else {
                          setAmount(utils.ceil(convertedAmount, selectedChain.precision));
                        }
                      }}
                      type='number'
                      InputProps={{
                        endAdornment: <InputAdornment position='end'>{'€UR'}</InputAdornment>,
                      }}
                    />
                  </Grid>
                </Grid>
              </Grid>
            }
            {isLoading &&
              <Grid item xs={12}>
                <Grid container justify='center'>
                  <CircularProgress />
                </Grid>
              </Grid>
            }
            {selectedCurrency && selectedChain &&
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={12}>
                    <Typography variant='subtitle2' component='span'>
                      {t('EXCHANGE_RATE')}
                    </Typography>
                    <Typography variant='subtitle1' component='span'>
                      {` 1 ${selectedCurrency.code.toUpperCase()} = ${utils.currencyFormat(selectedCurrency.rate)} €UR`}
                    </Typography>
                    <Typography variant='body2' component='p' color='textSecondary'>
                      {t('EXCHANGE_RATE_DETAILS')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='subtitle2' component='span'>
                      {t('FEE')}
                    </Typography>
                    <Typography variant='subtitle1' component='span'>
                      {` ${selectedChain.fee} %`}
                    </Typography>
                    <Typography variant='body2' component='p' color='textSecondary'>
                      {t('FEE_DETAILS')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='subtitle2' component='span'>
                      {t('MIN_MAX_AMOUNTS')}
                    </Typography>
                    <Typography variant='subtitle1' component='span'>
                      {` ${utils.ceil(selectedChain.min_amount, selectedChain.precision)} - ${utils.truncate(selectedChain.max_amount, selectedChain.precision)} ${selectedCurrency.code.toUpperCase()}`}
                    </Typography>
                    <Typography variant='body2' component='p' color='textSecondary'>
                      {t('MIN_MAX_DETAILS')}
                    </Typography>
                  </Grid>
                  <Grid item xs={12}>
                    <Typography variant='subtitle2' component='span'>
                      {t('TIMEOUT')}
                    </Typography>
                    <Typography variant='subtitle1' component='span'>
                      {` ${moment.duration(selectedChain.notification_timeout * 1000).humanize()}`}
                    </Typography>
                    <Typography variant='body2' component='p' color='textSecondary'>
                      {t('TIMEOUT_DETAILS')}
                    </Typography>
                  </Grid>
                </Grid>
              </Grid>
            }
          </Grid>
        </Login>
      </DepositWizard>
    </>
  );
}


