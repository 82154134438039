import React, { Reducer, Dispatch } from 'react';
import { Api } from './services/api.service';
import { Config } from './config';
import { Auth } from './services/auth.service';
import { Topup } from './services/types/topup.type';
import { Wallet } from './services/types/wallet.type';

const config = new Config();
type Data = {
  isSignedIn: boolean;
  topup: {
    data: Topup;
    wallet: Wallet;
  } | null
};

export type Context = {
  data: Data;
  setData: Dispatch<Partial<Data>>,
  reset: () => void
};

const RevoluexContext = React.createContext<Context | undefined>(undefined);

export function useApi(): Api {
  const context = React.useContext(RevoluexContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of RevoluexProvider');
  }
  return new Api(config, new Auth(config, context));
}

export function useAuth(): Auth {
  const context = React.useContext(RevoluexContext);
  if (context === undefined) {
    throw new Error('Use api must be used inside of RevoluexProvider');
  }
  return new Auth(config, context);
}

export function useContext(): Context {
  const context = React.useContext(RevoluexContext);
  if (context === undefined) {
    throw new Error('Use Store must be used inside of RevoluexProvider');
  }
  return context;
}

export const RevoluexProvider = ({ children }: { children: any }) => {
  const reducer: Reducer<Data, Partial<Data>> = (state: Data, action: Partial<Data>) => {
    return {
      ...state,
      ...action
    };
  }

  const [data, setData] = React.useReducer(
    reducer,
    {
      isSignedIn: false,
      topup: null
    }
  );

  const reset = () => {
    setData({
      topup: null
    });
  }

  return (
    <RevoluexContext.Provider value={{ data, setData, reset }}>
      {children}
    </RevoluexContext.Provider>
  )
}
