import cryptoIcons from '../cryptoicons';

const utils = {

  countryToFlag: (isoCode: string) => {
    return typeof String.fromCodePoint !== 'undefined'
      ? isoCode
        .toUpperCase()
        .replace(/./g, (char) => String.fromCodePoint(char.charCodeAt(0) + 127397))
      : isoCode;
  },

  round(input: number, decimals: number = 2): number {
    return Number(
      Math.round(Number(input + 'e' + decimals))
      + 'e-' + decimals,
    );
  },

  truncate(input: number, decimals: number = 2): number {
    return Number(
      Math.floor(Number(input + 'e' + decimals))
      + 'e-' + decimals,
    );
  },

  ceil(input: number, decimals: number = 2): number {
    return Number(
      Math.ceil(Number(input + 'e' + decimals))
      + 'e-' + decimals,
    );
  },

  runAsync: (runnable: () => Promise<void>, onDone?: (error?: any) => void) => {
    runnable().then(() => {
      if (onDone) {
        onDone();
      }
    }).catch(e => {
      if (e) {
        console.error(e);
      }
      if (onDone) {
        onDone(e);
      }
    });
  },

  currencyFormat: (aNumber: number, fractionDigits: number = 2) => new Intl.NumberFormat(
    ['es-ES', 'en-GB', 'en-US'],
    { style: 'decimal', minimumFractionDigits: fractionDigits, maximumFractionDigits: fractionDigits }
  ).format(aNumber),

  shuffleArray: (arrayToShuffle: any[]) => {
    for (let i = arrayToShuffle.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [arrayToShuffle[i], arrayToShuffle[j]] = [arrayToShuffle[j], arrayToShuffle[i]];
    }
    return arrayToShuffle;
  },

  getOrDefault: (value?: string, def = ''): string => {
    if (value) return value;
    return def;
  },

  getCryptoIcon: (cryptoCode: string) => cryptoIcons[cryptoCode.toLowerCase()] ? cryptoIcons[cryptoCode.toLowerCase()] : cryptoIcons.generic,


}
export default utils
