import React from 'react';
import { Container, Typography, Paper, Grid, MenuItem, MenuList, withStyles } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { Login } from '../components/login';
import { useAuth } from '../context';
import { Topups } from './topups';
import { Limits } from './limits';


enum SelectableMenuItem {
  TOPUPS, LIMITS
}

const Component = () => {
  const { t } = useTranslation('account');
  const auth = useAuth();
  const StyledMenuItem = withStyles((theme) => ({
    root: {
      '&$selected': {
        backgroundColor: theme.palette.primary.main,
        color: theme.palette.primary.contrastText,
        '&:hover': {
          backgroundColor: theme.palette.primary.main,
        }
      },
    },
    selected: {},
  }))(MenuItem);

  const [selectedItem, selectItem] = React.useState<SelectableMenuItem>(SelectableMenuItem.TOPUPS);

  const getContent = () => {
    if (selectedItem === SelectableMenuItem.TOPUPS) {
      return <Topups />
    } else {
      return <Limits />
    }
  }

  return (
    <Container>
      <Grid container>
        <Grid item xs={12}>
          <Typography variant='h3' gutterBottom>
            {t('ACCOUNT')}
          </Typography>
        </Grid>
        <Grid item xs={12}>
          <Grid container spacing={2}>
            <Grid item xs={12} md={3} sm={4}>
              <Paper>
                <MenuList>
                  <StyledMenuItem
                    onClick={() => selectItem(SelectableMenuItem.TOPUPS)}
                    selected={selectedItem === SelectableMenuItem.TOPUPS} >
                    {t('TOPUPS')}
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => selectItem(SelectableMenuItem.LIMITS)}
                    selected={selectedItem === SelectableMenuItem.LIMITS}>
                    {t('LIMITS')}
                  </StyledMenuItem>
                  <StyledMenuItem
                    onClick={() => auth.logout()}>
                    {t('LOGOUT')}
                  </StyledMenuItem>
                </MenuList>
              </Paper>
            </Grid>
            <Grid item xs={12} md={9} sm={8} style={{ width: 0 }}>
              {getContent()}
            </Grid>
          </Grid>
        </Grid>
      </Grid>
    </Container >
  )
}

export const Account = () => {
  return (
    <Login>
      <Component />
    </Login>
  );
}