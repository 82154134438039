import React from 'react';
import {
  Container,
  Typography,
  Box,
  Stepper,
  Step,
  StepLabel,
  Paper,
  useMediaQuery,
  useTheme,
  StepContent,
  Grid,
  Button,
  makeStyles,
  Theme,
  createStyles
} from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import { LoadingButton } from '../components/loadingButton';

export enum DepositWizardStep {
  CHAIN, WALLET
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    paper: {
      paddingTop: theme.spacing(3)
    },
    stepper: {
      padding: 0,
      backgroundColor: 'inherit'
    }
  }),
);

export const DepositWizard = ({
  children,
  step,
  next,
  back,
  canGoNext = true,
  showButtons = true,
  loading = false
}: {
  children: any,
  step: DepositWizardStep,
  next: () => void,
  back?: () => void,
  canGoNext: boolean,
  loading?: boolean,
  showButtons?: boolean
}) => {
  const { t } = useTranslation('wizard');
  const theme = useTheme();
  const isXs = useMediaQuery(theme.breakpoints.down('xs'));
  const classes = useStyles();
  const getStepProps = (currentStep: DepositWizardStep) => {
    return {
      key: currentStep,
      completed: step > currentStep,
      active: currentStep === step
    }
  }
  const getStepContent = (currentStep: DepositWizardStep) => {
    if (isXs && currentStep === step) {
      return (
        <StepContent>
          <Grid container spacing={4}>
            <Grid item>
              {children}
            </Grid>
            {getButtons()}
          </Grid>
        </StepContent>
      );
    }
    else if (isXs) return <StepContent />;
    else return null;
  }

  const getButtons = () => {
    if (!showButtons) return null;
    return (
      <Grid item xs={12}>
        <Grid container spacing={2} justify='flex-end'>
          {step === DepositWizardStep.CHAIN &&
            <Grid item>
              <LoadingButton
                variant='contained'
                color='primary'
                disabled={!canGoNext || loading}
                loading={loading}
                onClick={next}>
                {t('NEXT')}
              </LoadingButton>
            </Grid>
          }
        </Grid>
      </Grid>
    )
  }

  const getStepper = () => (
    <Grid container direction='column'>
      <Grid item>
        <Stepper
          orientation={isXs ? 'vertical' : 'horizontal'}
          className={classes.stepper}
          alternativeLabel={!isXs}>
          <Step {...getStepProps(DepositWizardStep.CHAIN)}>
            <StepLabel>{t('CHAIN')}</StepLabel>
            {getStepContent(DepositWizardStep.CHAIN)}
          </Step>
          <Step {...getStepProps(DepositWizardStep.WALLET)}>
            <StepLabel>{t('WALLET')}</StepLabel>
            {getStepContent(DepositWizardStep.WALLET)}
          </Step>
        </Stepper>
      </Grid>
      {!isXs &&
        <Grid item>
          <Box padding={2}>
            <Grid container direction='column' spacing={2}>
              <Grid item>
                {children}
              </Grid>
              {getButtons()}
            </Grid>
          </Box>
        </Grid>
      }
    </Grid>
  )

  return (
    <Container>
      <Typography variant='h3' gutterBottom>
        {t('NEW_TOPUP')}
      </Typography>
      {isXs &&
        getStepper()
      }
      {!isXs &&
        <Paper className={classes.paper}>
          {getStepper()}
        </Paper>
      }
    </Container>
  );
}