export enum TopupStatus {
  PENDING = 'PENDING',
  CONFIRMED = 'CONFIRMED',
  APPROVED = 'APPROVED',
  TRADED = 'TRADED',
  TRANSFERED = 'TRANSFERED',
  TIME_OUT = 'TIME_OUT'
}

export type Topup = {
  id: number;
  crypto_currency_code: string;
  crypto_chain_code: string;
  crypto_amount: number;
  transfer_amount: number | null,
  fee: number | null;
  created: string;
  updated: string | null;
  status: TopupStatus;
  deposit_crypto_amount?: number;
  deposit_id?:number;
}