import React from 'react';
import { Container, Typography, Box, Grid, makeStyles, Theme, createStyles, List, ListItem, ListItemAvatar, Avatar, ListItemText, IconButton } from '@material-ui/core';
import { useTranslation } from 'react-i18next';
import EmailIcon from '@material-ui/icons/Email';
import LocationIcon from '@material-ui/icons/LocationCity';
import FacebookIcon from '@material-ui/icons/Facebook';
import TwitterIcon from '@material-ui/icons/Twitter';
import LinkedInIcon from '@material-ui/icons/LinkedIn';
import mapImg from '../assets/img/staticmap.png';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      width: '100%',
    },
    icon: {
      width: theme.spacing(6),
      height: theme.spacing(6),
      color: theme.palette.primary.contrastText,
      backgroundColor: theme.palette.secondary.main
    }
  }),
);

export const Contact = () => {
  const { t } = useTranslation('contact');
  const classes = useStyles();

  return (
    <Container>
      <Typography variant='h3' gutterBottom>
        {t('CONTACT')}
      </Typography>
      <Box marginTop={6}>
        <Grid container spacing={2} alignItems='center'>
          <Grid item xs={12} sm={6}>
            <Grid container alignContent='center'>
              <Grid item xs={12}>
                <List className={classes.root}>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className={classes.icon}>
                        <LocationIcon fontSize={'large'} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="REVOLUPAY EP S.L." secondary="Vallespir, 19 1 - 08173, Sant Cugat del Vallès - Barcelona" />
                  </ListItem>
                  <ListItem>
                    <ListItemAvatar>
                      <Avatar className={classes.icon}>
                        <EmailIcon fontSize={'large'} />
                      </Avatar>
                    </ListItemAvatar>
                    <ListItemText primary="contact@revolupay.es" />
                  </ListItem>
                </List>
              </Grid>
              <Grid item xs={12}>
                <Grid container justify='center'>
                  <Grid item>
                    <IconButton component='a' rel='noopener' target='_blank' href='https://www.facebook.com/pages/category/Finance/RevoluPay-928692330663160/' aria-label='facebook'>
                      <FacebookIcon fontSize={'large'} color='primary' />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton component='a' rel='noopener' target='_blank' href='https://twitter.com/revolu_pay' aria-label='twitter'>
                      <TwitterIcon fontSize={'large'} color='primary' />
                    </IconButton>
                  </Grid>
                  <Grid item>
                    <IconButton component='a' rel='noopener' target='_blank' href='https://ve.linkedin.com/company/revolupay' aria-label='linkedin'>
                      <LinkedInIcon fontSize={'large'} color='primary' />
                    </IconButton>
                  </Grid>
                </Grid>
              </Grid>
            </Grid>
          </Grid>
          <Grid item xs={12} sm={6}>
            <img style={{ maxWidth: '100%' }} src={mapImg} />
          </Grid>
        </Grid>
      </Box>
    </Container >
  );
}