import React from 'react';
import {
  BrowserRouter,
  Switch,
  Route as DomRoute,
  useLocation
} from 'react-router-dom';
import { Home } from './home/home';
import { Layout } from './layout/layout';
import { About } from './static/about';
import { Terms } from './static/terms';
import { Privacy } from './static/privacy';
import { Cookies } from './static/cookies';
import { Credits } from './static/credits';
import { Contact } from './contact/contact';
import { Account } from './account/account';
import { Wallet } from './deposit/wallet';
import { useAuth } from './context';
import ReactGA from 'react-ga';
import { Config } from './config';
import { Chain } from './deposit/chain';

const config = new Config();
if (config.GOOGLE_ANALITYCS_ID) {
  ReactGA.initialize(config.GOOGLE_ANALITYCS_ID);
}

const Route = ({ component: Component, ...rest }: { component: any, exact?: boolean, path: string[] | string }) => (
  <DomRoute {...rest} render={(props) => <Layout><Component {...props} /></Layout>} />
)

const ScrollToTop = () => {
  const { pathname } = useLocation();
  React.useEffect(() => {
    window.scrollTo(0, 0);
    if (config.GOOGLE_ANALITYCS_ID) {
      ReactGA.pageview(pathname);
    }
  }, [pathname]);
  return null;
}

export const Router = () => {
  const auth = useAuth();
  React.useEffect(() => {
    auth.attemptCookieLogin();
  }, []);
  return (
    <BrowserRouter>
      <ScrollToTop />
      <Switch>
        <Route exact path={['/deposit','/deposit/chain']} component={Chain} />
        <Route exact path='/deposit/wallet' component={Wallet} />
        <Route exact path='/about' component={About} />
        <Route exact path='/terms' component={Terms} />
        <Route exact path='/privacy' component={Privacy} />
        <Route exact path='/cookies' component={Cookies} />
        <Route exact path='/credits' component={Credits} />
        <Route exact path='/contact' component={Contact} />
        <Route exact path='/account' component={Account} />
        <Route path='/' component={Home} />
      </Switch>
    </BrowserRouter>
  );
}
